import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import ScoreByColor from '../../components/ExcerciseTypes/Design/ScoreByColor'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import PaintColorType from '../../components/ExcerciseTypes/Design/PaintColorType'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 3',
		id: '4WB-U3-P23-E1',
		audio: '',
		video: '',
		component: MatchDots,
		exerciseKey: 'img/FriendsPlus/Page23/E1/Key/E1answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: 'Match.',
				color: '#2E479B',
			},
		],
		question: {
			DrawLines: {
				// multipleLine: true,
				boxMatch: [
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '201px',
							left: '106px',
							width: 199,
							height: 102,
							// backgroundColor: "blue",
							// border: "1px solid",
						},
					}, //0
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '317px',
							left: '106px',
							width: 199,
							height: 102,
							// backgroundColor: "red",
							// border: "1px solid",
						},
					}, //1
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '434px',
							left: '106px',
							width: 199,
							height: 102,
							// backgroundColor: "yellow",
							// border: "1px solid",
						},
					}, //2
					//right
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '88px',
							left: '463px',
							width: 255,
							height: 66,
							// backgroundColor: "pink",
							// border: "1px solid",
						},
					}, //3
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '208px',
							left: '452px',
							width: 244,
							height: 63,
							// backgroundColor: "blue",
							// border: "1px solid",
						},
					}, //4
					{
						boxMatchStyle: {
							position: 'absolute',
							top: '435px',
							left: '433px',
							width: 210,
							height: 66,
							// backgroundColor: "yellow",
							// border: "1px solid",
						},
					}, //5
				],
				answers: ['0-5', '1-3', '2-4'],
				initialValue: [],
			},
			Layout: `
      <img style='width:16cm ;padding-top:30px; margin-bottom:50px' src="img/FriendsPlus/Page23/E1/1.jpg" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />

          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          
      `,
		},
	},
	2: {
		// Exercise num
		unit: 'Unit 3',
		id: '4WB-U3-P23-E2',
		audio: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page23/E2/Key/E2answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 105,
		textAlign: 'center',
		maxLength: 15,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 23,
			height: 22,
			width: 190,
		},
		titleQuestion: [
			{
				num: '2',
				title: '  Write.',
				color: '#234090',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `

        <div style='position: relative;margin-top:20px; width:100%; text-align:right; line-height:20px' >
            <img style='width:20cm' src="img/FriendsPlus/Page23/E2/1.jpg" />    
                <div style=" position: absolute; top: 199px; left: 110px"><input id='0'></input></div>
                <div style=" position: absolute; top: 243px; left: 261px"><input id='1'></input></div>
                <div style=" position: absolute; top: 287px; left: 488px"><input id='2'></input></div>
                <div style=" position: absolute; top: 378px; left: 380px"><input id='3'></input></div>
            
            </div>
            
          `,
				answer: ['I would like', 'Would he like', 'He likes', 'I like'],
			},
		],
	},
}

export default json
