import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import TextEditer from "../../components/ExcerciseTypes/TextEditer";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 6",
    id: "4WB-U6-P44-E1",
    audio: "Audios/Track 23.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page44/E1/Key/E1answerKey.png",

    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the picture. Listen to a conversation. Fill in the correct circle.  <headphone name='23' src='Audios/Track 23.mp3'></headphone>",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page44/E1/1.jpg" }],
      // Row 1
      [
        { url: "img/FriendsPlus/Page44/E1/2.jpg" },
        { url: "img/FriendsPlus/Page44/E1/3.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page44/E1/4.jpg" }],
      [
        { url: "img/FriendsPlus/Page44/E1/5.jpg" },
        { url: "img/FriendsPlus/Page44/E1/6.jpg", input: 1 },
      ],
      [{ url: "img/FriendsPlus/Page44/E1/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page44/E1/8.jpg" },
        { url: "img/FriendsPlus/Page44/E1/9.jpg", input: 1, isCorrect: true },
      ],
      [{ url: "img/FriendsPlus/Page44/E1/10.jpg" }],
      // Row 2
      [
        { url: "img/FriendsPlus/Page44/E1/11.jpg" },
        { url: "img/FriendsPlus/Page44/E1/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page44/E1/13.jpg" },
        { url: "img/FriendsPlus/Page44/E1/14.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page44/E1/15.jpg" }],
      [
        { url: "img/FriendsPlus/Page44/E1/16.jpg" },
        { url: "img/FriendsPlus/Page44/E1/17.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page44/E1/18.jpg" },
        { url: "img/FriendsPlus/Page44/E1/19.jpg", input: 3 },
      ],
      [{ url: "img/FriendsPlus/Page44/E1/20.jpg" }],
      [
        { url: "img/FriendsPlus/Page44/E1/21.jpg" },
        { url: "img/FriendsPlus/Page44/E1/22.jpg", input: 2 },
        { url: "img/FriendsPlus/Page44/E1/23.jpg" },
        { url: "img/FriendsPlus/Page44/E1/24.jpg", input: 3, isCorrect: true },
      ],
    ],
  },
  2: {
    unit: "Unit 6",
    id: "4WB-U6-P44-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page44/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Read the song in the Student Book. Write the answers. 
			  `,
        color: "#92278f",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 50,
    maxLength: 20,
    checkUppercase: true,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 25,
      height: 29,
      // textTransform: 'uppercase',
      width: 280,
    },
    textAlign: "center",
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div>
            <img src='img/FriendsPlus/Page44/E2/1.jpg' width='95%'>
          </div>
          <div style='display:flex; line-height: 50px'>
            <div style='list-style-type:decimal; width: 340px; margin-left:70px'>
             <li>Are the monkeys eating?</li>
             <li>Are the zebras running?</li>
             <li>Are the tigers playing?</li>
             <li>Are the parrots talking?</li>
             <li>Are the penguins walking?</li>
             <li>Are the parrots flying?</li>
            </div>
            <div style='width: 280px'>
              <div style="text-align: center;border-bottom: 1px solid black">No, they aren't.</div>
              <input id='0'></input>
              <input id='1'></input>
              <input id='2'></input>
              <input id='3'></input>
              <input id='4'></input>
            </div>
            <img src='img/FriendsPlus/Page44/E2/2.jpg' height='250px style='list-style-type:decimal; width: 340px style='margin-left:80px'''>
          </div>
			  `,
        answer: [
          "Yes, they are./Yes, they are",
          "No, they aren't./No, they aren't",
          "Yes, they are./Yes, they are",
          "No, they aren't./No, they aren't",
          "Yes, they are./Yes, they are",
        ],
      },
    ],
  },
};

export default json;
