import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDotsV2 from "../../components/ExcerciseTypes/LineTo/MatchDotsV2";
const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P64-E1",
    audio: "",
    video: "",
    component: MatchDotsV2,
    exerciseKey: "img/FriendsPlus/Page64/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Read and draw the times on the clocks.",
        color: "#2E479B",
      },
    ],
    question: {
      DrawLines: {
        fontWeight: "120px",
        multipleLine: true,
        boxMatch: [
          //Clock 2
          //minute
          {
            boxMatchStyle: {
              position: "absolute",
              top: "98px",
              left: "663px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //0/12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "109px",
              left: "701px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "136px",
              left: "725px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "171px",
              left: "738px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "205px",
              left: "725px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "229px",
              left: "698px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "240px",
              left: "663px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "228px",
              left: "628px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "207px",
              left: "600px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "170px",
              left: "588px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "134px",
              left: "608px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "111px",
              left: "627px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //11

          //hour
          {
            boxMatchStyle: {
              position: "absolute",
              top: "126px",
              left: "663px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //0/12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "134px",
              left: "682px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "699px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "172px",
              left: "706px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "194px",
              left: "701px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "208px",
              left: "683px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "212px",
              left: "663px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "207px",
              left: "641px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "192px",
              left: "624px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "170px",
              left: "618px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "149px",
              left: "628px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "133px",
              left: "642px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //11

          //CLOCK 3
          //minute
          {
            boxMatchStyle: {
              position: "absolute",
              top: "531px",
              left: "213px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //12/12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "538px",
              left: "241px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "562px",
              left: "258px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "587px",
              left: "272px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "615px",
              left: "260px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //16
          {
            boxMatchStyle: {
              position: "absolute",
              top: "633px",
              left: "239px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //17
          {
            boxMatchStyle: {
              position: "absolute",
              top: "641px",
              left: "212px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //18
          {
            boxMatchStyle: {
              position: "absolute",
              top: "631px",
              left: "186px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //19
          {
            boxMatchStyle: {
              position: "absolute",
              top: "616px",
              left: "163px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //20
          {
            boxMatchStyle: {
              position: "absolute",
              top: "588px",
              left: "152px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //21
          {
            boxMatchStyle: {
              position: "absolute",
              top: "561px",
              left: "169px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //22
          {
            boxMatchStyle: {
              position: "absolute",
              top: "542px",
              left: "187px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //23

          //hour
          {
            boxMatchStyle: {
              position: "absolute",
              top: "555px",
              left: "213px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //12/12
          {
            boxMatchStyle: {
              position: "absolute",
              top: "562px",
              left: "229px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //13
          {
            boxMatchStyle: {
              position: "absolute",
              top: "574px",
              left: "239px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //14
          {
            boxMatchStyle: {
              position: "absolute",
              top: "589px",
              left: "244px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //15
          {
            boxMatchStyle: {
              position: "absolute",
              top: "605px",
              left: "239px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //16
          {
            boxMatchStyle: {
              position: "absolute",
              top: "617px",
              left: "227px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //17
          {
            boxMatchStyle: {
              position: "absolute",
              top: "622px",
              left: "211px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //18
          {
            boxMatchStyle: {
              position: "absolute",
              top: "613px",
              left: "195px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //19
          {
            boxMatchStyle: {
              position: "absolute",
              top: "602px",
              left: "183px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //20
          {
            boxMatchStyle: {
              position: "absolute",
              top: "588px",
              left: "177px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //21
          {
            boxMatchStyle: {
              position: "absolute",
              top: "572px",
              left: "184px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //22
          {
            boxMatchStyle: {
              position: "absolute",
              top: "561px",
              left: "197px",
              width: 11,
              height: 11,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //23

          //	CLOCK 4
          //minute
          {
            boxMatchStyle: {
              position: "absolute",
              top: "532px",
              left: "686px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //0/24
          {
            boxMatchStyle: {
              position: "absolute",
              top: "540px",
              left: "721px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //25
          {
            boxMatchStyle: {
              position: "absolute",
              top: "566px",
              left: "744px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //26
          {
            boxMatchStyle: {
              position: "absolute",
              top: "602px",
              left: "758px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //27
          {
            boxMatchStyle: {
              position: "absolute",
              top: "636px",
              left: "746px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //28
          {
            boxMatchStyle: {
              position: "absolute",
              top: "661px",
              left: "720px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //29
          {
            boxMatchStyle: {
              position: "absolute",
              top: "671px",
              left: "685px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //30
          {
            boxMatchStyle: {
              position: "absolute",
              top: "657px",
              left: "644px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //31
          {
            boxMatchStyle: {
              position: "absolute",
              top: "634px",
              left: "622px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //32
          {
            boxMatchStyle: {
              position: "absolute",
              top: "602px",
              left: "611px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //33
          {
            boxMatchStyle: {
              position: "absolute",
              top: "565px",
              left: "629px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //34
          {
            boxMatchStyle: {
              position: "absolute",
              top: "541px",
              left: "650px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //35

          //hour
          {
            boxMatchStyle: {
              position: "absolute",
              top: "562px",
              left: "686px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //0/24
          {
            boxMatchStyle: {
              position: "absolute",
              top: "568px",
              left: "705px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //25
          {
            boxMatchStyle: {
              position: "absolute",
              top: "583px",
              left: "718px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //26
          {
            boxMatchStyle: {
              position: "absolute",
              top: "602px",
              left: "727px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //27
          {
            boxMatchStyle: {
              position: "absolute",
              top: "623px",
              left: "720px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //28
          {
            boxMatchStyle: {
              position: "absolute",
              top: "639px",
              left: "705px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //29
          {
            boxMatchStyle: {
              position: "absolute",
              top: "644px",
              left: "685px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //30
          {
            boxMatchStyle: {
              position: "absolute",
              top: "638px",
              left: "660px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //31
          {
            boxMatchStyle: {
              position: "absolute",
              top: "620px",
              left: "646px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //32
          {
            boxMatchStyle: {
              position: "absolute",
              top: "599px",
              left: "640px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //33
          {
            boxMatchStyle: {
              position: "absolute",
              top: "579px",
              left: "649px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //34
          {
            boxMatchStyle: {
              position: "absolute",
              top: "567px",
              left: "665px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //35

          //	CLOCK 5
          //minute
          {
            boxMatchStyle: {
              position: "absolute",
              top: "985px",
              left: "207px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //0/36
          {
            boxMatchStyle: {
              position: "absolute",
              top: "991px",
              left: "243px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //37
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1019px",
              left: "269px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //38
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1057px",
              left: "283px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //39
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1092px",
              left: "269px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //40
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1119px",
              left: "240px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //41
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1128px",
              left: "206px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //42
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1116px",
              left: "168px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //43
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1095px",
              left: "141px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //44
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1058px",
              left: "128px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //45
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1025px",
              left: "149px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //46
          {
            boxMatchStyle: {
              position: "absolute",
              top: "995px",
              left: "166px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //47

          //hour
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1013px",
              left: "207px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //0/36
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1019px",
              left: "226px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //37
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1036px",
              left: "241px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //38
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1058px",
              left: "246px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //39
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1078px",
              left: "239px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //40
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1093px",
              left: "225px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //41
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1099px",
              left: "206px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //42
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1091px",
              left: "183px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //43
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1078px",
              left: "167px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //44
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1058px",
              left: "161px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //45
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1038px",
              left: "169px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //46
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1019px",
              left: "182px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //47

          //	CLOCK 6
          //minute
          {
            boxMatchStyle: {
              position: "absolute",
              top: "996px",
              left: "694px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //0/48
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1003px",
              left: "725px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //49
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1028px",
              left: "746px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //50
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1057px",
              left: "755px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //51
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1086px",
              left: "748px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //52
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1109px",
              left: "725px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //53
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1119px",
              left: "694px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //54
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1107px",
              left: "660px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //55
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1090px",
              left: "641px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //56
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1060px",
              left: "631px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //57
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1030px",
              left: "644px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //58
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1007px",
              left: "659px",
              width: 13,
              height: 13,
              backgroundColor: "rgb(102,109,101)",
              borderRadius: "50px",
            },
          }, //59

          //hour
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1021px",
              left: "694px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //0/48
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1026px",
              left: "710px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //49
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1041px",
              left: "723px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //50
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1058px",
              left: "729px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //51
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1075px",
              left: "724px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //52
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1088px",
              left: "711px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //53
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1094px",
              left: "694px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //54
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1090px",
              left: "674px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //55
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1077px",
              left: "662px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //56
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1061px",
              left: "656px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //57
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1043px",
              left: "662px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //58
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1028px",
              left: "675px",
              width: 12,
              height: 12,
              backgroundColor: "rgb(245,54,92)",
              borderRadius: "50px",
            },
          }, //59

          //	MID DOTS
          {
            boxMatchStyle: {
              position: "absolute",
              top: "170px",
              left: "663px",
              width: 13,
              height: 13,
              backgroundColor: "black",
              borderRadius: "50px",
            },
          }, //60

          {
            boxMatchStyle: {
              position: "absolute",
              top: "588px",
              left: "212px",
              width: 13,
              height: 13,
              backgroundColor: "black",
              borderRadius: "50px",
            },
          }, //61
          {
            boxMatchStyle: {
              position: "absolute",
              top: "602px",
              left: "684px",
              width: 13,
              height: 13,
              backgroundColor: "black",
              borderRadius: "50px",
            },
          }, //62
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1058px",
              left: "205px",
              width: 13,
              height: 13,
              backgroundColor: "black",
              borderRadius: "50px",
            },
          }, //63
          {
            boxMatchStyle: {
              position: "absolute",
              top: "1058px",
              left: "693px",
              width: 13,
              height: 13,
              backgroundColor: "black",
              borderRadius: "50px",
            },
          }, //64
        ],
        answers: [
          "120-20",
          "120-3",
          "121-47",
          "121-24",
          "122-62",
          "122-54",
          "123-88",
          "123-81",
          "117-124",
          "102-124",
        ],
        initialValue: [],
      },
      Layout: `
      <div><i style='font-size:18px; color: red'>*Please select the hour (red) first and the minute (gray) after.</i></div>
      <div style='position: relative; font-size: 24px; color: black; font-weight: 600'>
      
      <img style='width: 23cm; margin-bottom:50px' src="img/FriendsPlus/Page64/E1/3.jpg" />
      <div style='position: absolute; top: 329px; left: 112px'>It’s three o’clock.</div>
      <div style='position: absolute; top: 329px; left: 574px'>It’s eight fifteen.</div>
      <div style='position: absolute; top: 759px; left: 112px'>It’s eleven o’clock.</div>
      <div style='position: absolute; top: 759px; left: 599px'>It’s two thirty.</div>
      <div style='position: absolute; top: 1236px; left: 112px'>It’s four forty-five.</div>
      <div style='position: absolute; top: 1236px; left: 599px'>It’s nine thirty.</div>
     

          <input id='0' type='boxMatch' />
          <input id='1' type='boxMatch' />
          <input id='2' type='boxMatch' />
          <input id='3' type='boxMatch' />
          <input id='4' type='boxMatch' />
          <input id='5' type='boxMatch' />
          <input id='6' type='boxMatch' />
          <input id='7' type='boxMatch' />
          <input id='8' type='boxMatch' />
          <input id='9' type='boxMatch' />
          <input id='10' type='boxMatch' />
          <input id='11' type='boxMatch' />

          <input id='12' type='boxMatch' />
          <input id='13' type='boxMatch' />
          <input id='14' type='boxMatch' />
          <input id='15' type='boxMatch' />
          <input id='16' type='boxMatch' />
          <input id='17' type='boxMatch' />
          <input id='18' type='boxMatch' />
          <input id='19' type='boxMatch' />
          <input id='20' type='boxMatch' />
          <input id='21' type='boxMatch' />
          <input id='22' type='boxMatch' />
          <input id='23' type='boxMatch' />
          

					<input id='24' type='boxMatch' />
					<input id='25' type='boxMatch' />
					<input id='26' type='boxMatch' />
					<input id='27' type='boxMatch' />
					<input id='28' type='boxMatch' />
					<input id='29' type='boxMatch' />
					<input id='30' type='boxMatch' />
					<input id='31' type='boxMatch' />
					<input id='32' type='boxMatch' />
					<input id='33' type='boxMatch' />
					<input id='34' type='boxMatch' />
					<input id='35' type='boxMatch' />

          <input id='36' type='boxMatch' />
					<input id='37' type='boxMatch' />
					<input id='38' type='boxMatch' />
					<input id='39' type='boxMatch' />
					<input id='40' type='boxMatch' />
					<input id='41' type='boxMatch' />
					<input id='42' type='boxMatch' />
					<input id='43' type='boxMatch' />
					<input id='44' type='boxMatch' />
					<input id='45' type='boxMatch' />
					<input id='46' type='boxMatch' />
					<input id='47' type='boxMatch' />

          
					<input id='48' type='boxMatch' />
					<input id='49' type='boxMatch' />
					<input id='50' type='boxMatch' />
					<input id='51' type='boxMatch' />
					<input id='52' type='boxMatch' />
					<input id='53' type='boxMatch' />
					<input id='54' type='boxMatch' />
					<input id='55' type='boxMatch' />
					<input id='56' type='boxMatch' />
					<input id='57' type='boxMatch' />
					<input id='58' type='boxMatch' />
					<input id='59' type='boxMatch' />

					<input id='60' type='boxMatch' />
					<input id='61' type='boxMatch' />
					<input id='62' type='boxMatch' />
					<input id='63' type='boxMatch' />
					<input id='64' type='boxMatch' />
					<input id='65' type='boxMatch' />
					<input id='66' type='boxMatch' />
					<input id='67' type='boxMatch' />
					<input id='68' type='boxMatch' />
					<input id='69' type='boxMatch' />
					<input id='70' type='boxMatch' />
					<input id='71' type='boxMatch' />



					<input id='72' type='boxMatch' />
					<input id='73' type='boxMatch' />
					<input id='74' type='boxMatch' />
					<input id='75' type='boxMatch' />
					<input id='76' type='boxMatch' />
					<input id='77' type='boxMatch' />
					<input id='78' type='boxMatch' />
					<input id='79' type='boxMatch' />
					<input id='80' type='boxMatch' />
					<input id='81' type='boxMatch' />
					<input id='82' type='boxMatch' />
					<input id='83' type='boxMatch' />
					

					<input id='84' type='boxMatch' />
					<input id='85' type='boxMatch' />
					<input id='86' type='boxMatch' />
					<input id='87' type='boxMatch' />
					<input id='88' type='boxMatch' />
					<input id='89' type='boxMatch' />
					<input id='90' type='boxMatch' />
					<input id='91' type='boxMatch' />
					<input id='92' type='boxMatch' />
					<input id='93' type='boxMatch' />
					<input id='94' type='boxMatch' />
					<input id='95' type='boxMatch' />
					


          <input id='96' type='boxMatch' />
					<input id='97' type='boxMatch' />
					<input id='98' type='boxMatch' />
					<input id='99' type='boxMatch' />
					<input id='100' type='boxMatch' />
					<input id='101' type='boxMatch' />
					<input id='102' type='boxMatch' />
					<input id='103' type='boxMatch' />
					<input id='104' type='boxMatch' />
					<input id='105' type='boxMatch' />
					<input id='106' type='boxMatch' />
					<input id='107' type='boxMatch' />
					

          <input id='108' type='boxMatch' />
					<input id='109' type='boxMatch' />
					<input id='110' type='boxMatch' />
					<input id='111' type='boxMatch' />
					<input id='112' type='boxMatch' />
					<input id='113' type='boxMatch' />
					<input id='114' type='boxMatch' />
					<input id='115' type='boxMatch' />
					<input id='116' type='boxMatch' />
					<input id='117' type='boxMatch' />
					<input id='118' type='boxMatch' />
					<input id='119' type='boxMatch' />
					


          <input id='120' type='boxMatch' />
					<input id='121' type='boxMatch' />
					<input id='122' type='boxMatch' />
					<input id='123' type='boxMatch' />
					<input id='124' type='boxMatch' />

          </div>
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P64-E2",
    audio: "Audios/Track 33.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page64/E2/Key/E2answerKey.png",

    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and look at the clocks. Fill in the correct circle. <headphone name='33' src='Audios/Track 33.mp3'></headphone>",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row 1
      [{ url: "img/FriendsPlus/Page64/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page64/E2/2.jpg" },
        { url: "img/FriendsPlus/Page64/E2/3.jpg", input: "1" },
        { url: "img/FriendsPlus/Page64/E2/4.jpg" },
        { url: "img/FriendsPlus/Page64/E2/5.jpg", input: "1" },
        { url: "img/FriendsPlus/Page64/E2/6.jpg" },
        { url: "img/FriendsPlus/Page64/E2/7.jpg", input: "1", isCorrect: true },
        { url: "img/FriendsPlus/Page64/E2/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page64/E2/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page64/E2/10.jpg" },
        {
          url: "img/FriendsPlus/Page64/E2/11.jpg",
          input: "2",
        },
        { url: "img/FriendsPlus/Page64/E2/12.jpg" },
        {
          url: "img/FriendsPlus/Page64/E2/13.jpg",
          input: "2",
          isCorrect: true,
        },
        { url: "img/FriendsPlus/Page64/E2/14.jpg" },
        { url: "img/FriendsPlus/Page64/E2/15.jpg", input: "2" },
      ],
      [
        { url: "img/FriendsPlus/Page64/E2/16.jpg" },
        {
          url: "img/FriendsPlus/Page64/E2/17.jpg",
          input: "3",
        },
        { url: "img/FriendsPlus/Page64/E2/18.jpg" },
        { url: "img/FriendsPlus/Page64/E2/19.jpg", input: "3" },
        { url: "img/FriendsPlus/Page64/E2/20.jpg" },
        {
          url: "img/FriendsPlus/Page64/E2/21.jpg",
          input: "3",
          isCorrect: true,
        },
        { url: "img/FriendsPlus/Page64/E2/22.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page64/E2/23.jpg" }],
    ],
  },
};

export default json;
