import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "4WB-U1-P11-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page11/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    component: T6,
    inputSize: 167,
    textAlign: "center",
    maxLength: 8,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 27,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Look and write. Use <i>we</i> or <i>they</i>.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <div style=" position: relative; ">
						<div> <img style='width: 19cm' src='img/FriendsPlus/Page11/E1/1.jpg' /> </div>
            <div style=" position: absolute; top: 215px; left: 424px; ">#</div>
						<div style=" position: absolute; top: 490px; left: 13px; ">#</div>
						<div style=" position: absolute; top: 490px; left: 425px; ">#</div>
						
					</div>
        
          
          `,
        answer: ["We're", "We're", "They're"],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P11-E2",
    audio: "Audios/Track 02.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page11/E2/Key/E2answerKey.png",

    component: DesignUnderLine,
    totalInput: 2,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and fill in the correct circle. <headphone name='02' src='Audios/Track 02.mp3'></headphone>",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      [{ url: "img/FriendsPlus/Page11/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E2/2.jpg" },
        { url: "img/FriendsPlus/Page11/E2/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page11/E2/4.jpg" },
        { url: "img/FriendsPlus/Page11/E2/5.jpg", input: 2 },
        { url: "img/FriendsPlus/Page11/E2/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E2/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page11/E2/8.jpg" },
        { url: "img/FriendsPlus/Page11/E2/9.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E2/10.jpg" },
        { url: "img/FriendsPlus/Page11/E2/11.jpg", input: 2 },
        { url: "img/FriendsPlus/Page11/E2/12.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page11/E2/13.jpg" }],

      [
        { url: "img/FriendsPlus/Page11/E2/14.jpg" },
        { url: "img/FriendsPlus/Page11/E2/15.jpg", input: 1 },
        { url: "img/FriendsPlus/Page11/E2/16.jpg" },
        { url: "img/FriendsPlus/Page11/E2/17.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page11/E2/18.jpg" },
      ],
    ],
  },

  3: {
    unit: "Unit 1",
    id: "SB7-U1-P11-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page11/E3/Key/E3answerKey.png",
    inputSize: 200,
    textAlign: "center",
    maxLength: 20,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
    },

    titleQuestion: [
      {
        num: "3",
        title: "Look at the pictures in 2 again. Write.",
        color: "#234090",
      },
    ],

    questionImage: [],
    questions: [
      {
        title: `
        <div style='border: 2px solid rgb(94,199,144);border-radius: 10px;text-align:center;width:30%; margin-bottom:20px'>fly &ensp; study &ensp; fight </div>
        <img style="width:20cm" src='img/FriendsPlus/Page11/E3/1.jpg' />
        <div style='display:flex; margin-top:30px'>
            <b>1.</b>&ensp; <div><u style='color:gray ' >&ensp;They fight&ensp;</div> fires.&ensp;</input>&ensp;
            <b>2.</b>&ensp; <input id='1' width='150px'> planes.</input>&ensp;
            <b>3.</b> # 
        </div>
        
        `,
        answer: ["They fly", "They study"],
      },
    ],
  },
};
export default json;
