import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Circle_Write3 from "../../components/ExcerciseTypes/Circle_Write3";

const json = {
  1: {
    unit: "Unit 8",
    id: "4WB-U8-P59-E1",
    audio: "Audios/Track 31.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page59/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Write. Listen and check. <headphone name='31' src='Audios/Track 31.mp3'></headphone> 
			  `,
        color: "#23408f",
      },
    ],
    hintBox: [
      {
        src: ["brother", "father", "throw", "<s>three</s>"],
        borderColor: "#f25822",
        width: 480,
        textAlign: "center",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 50,
    maxLength: 7,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 26,
      height: 44,
      // textTransform: 'uppercase',
      width: 200,
    },
    maxLength: 7,
    textAlign: "center",

    isHiddenCheck: true,
    selectStyle: {
      color: "#00ADFE",
      width: 60,
      textAlign: "center",
    },
    questions: [
      {
        title: `
					<div style=" position: relative ">
          <hintbox id='0'></hintbox>
            <img src='img/FriendsPlus/Page59/E1/1.jpg' width='95%'>
            <div style=" position: absolute; top: 205px; left: 500px; "><input id='0'></input></div>
            <div style=" position: absolute; top: 405px; left: 20px; "><input id='1'></input></div>
            <div style=" position: absolute; top: 405px; left: 500px; "><input id='2'></input></div>
					</div>
				
			  
			  `,
        answer: ["brother", "father", "throw"],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "4WB-U8-P59-E2",
    audio: "Audios/Track 32.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page59/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Write. Listen and chant. <headphone name='32' src='Audios/Track 32.mp3'></headphone> 
			  `,
        color: "#23408f",
      },
    ],
    hintBox: [
      {
        src: [
          "this",
          "mother",
          "<s>three</s>",
          "that",
          "this",
          "the",
          "brother",
          "father",
        ],
        borderColor: "#f25822",
        width: 810,
        textAlign: "center",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 50,
    maxLength: 7,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 24,
      // textTransform: 'uppercase',
      width: 100,
    },
    textAlign: "center",

    isHiddenCheck: true,
    selectStyle: {
      color: "#00ADFE",
      width: 60,
      textAlign: "center",
    },
    questions: [
      {
        title: `
        <hintbox id='0'></hintbox>
        <div style=" position: relative; width: 600px; margin-top:20px ">
        <div style=" position: absolute; top: 10px; left: 30px; ">
          <span>There are </span>
          <sup>1 </sup>
          <span style=" border-bottom: 2px solid black; color: gray ">three </span>
          <span>people in</span>
          <sup> 2</sup>
          <input id='0'></input>
          <span> picture.</span>
        </div>
        
        <div style=" position: absolute; top: 60px; left: 30px; ">
          <span>Who is </span>
          <sup>3 </sup>
          <input id='1'></input>
          <span> and who is </span>
          <sup>4</sup>
          <input id='2'></input>
          <span>?</span>
        </div>
        
        <div style=" position: absolute; top: 110px; left: 30px; ">
          <span>He's my </span>
          <sup>5 </sup>
          <input id='3'></input>
          <span>, she's my </span>
          <sup> 6</sup>
          <input id='4'></input>
          <span>.</span>
        </div>
        
        <div style=" position: absolute; top: 160px; left: 30px; ">
          <span>He's my </span>
          <sup>7 </sup>
          <input id='5'></input>
          <span>, that's </span>
          <sup> 8</sup>
          <input id='6'></input>
          <span>cat.</span>
        </div>
      </div>
          
          <img src='img/FriendsPlus/Page59/E2/1.jpg' width='30%' style="margin-left: 520px">
          
			  `,
        answer: [`this`, `this`, `that`, `father`, `mother`, `brother`, `the`],
      },
    ],
  },
  // 3: {
  //   unit: "Unit 8",
  //   id: "4WB-U8-P59-E3",
  //   audio: "",
  //   video: "",
  //   component: T6,
  //   exerciseKey: "img/FriendsPlus/Page59/E3/Key/E3answerKey.png",
  //   titleQuestion: [
  //     {
  //       num: "3",
  //       title: `
  // 			Circle and write the odd one out.
  // 		  `,
  //       color: "#23408f",
  //     },
  //   ],
  //   titleImage: "",
  //   questionImage: [],
  //   inputSize: 50,
  //   maxLength: 10,
  //   stylesTextInput: {
  //     background: "none",
  //     borderBottom: "none",
  //     fontSize: 26,
  //     height: 44,
  //     // textTransform: 'uppercase',
  //     width: 200,
  //   },
  //   maxLength: 7,
  //   textAlign: "center",
  //   isHiddenCheck: true,
  //   selectStyle: {
  //     color: "#00ADFE",
  //     width: 60,
  //     textAlign: "center",
  //   },
  //   questions: [
  //     {
  //       title: `
  // 				<div style=" position: relative ">
  //           <img src='img/FriendsPlus/Page59/E3/1.jpg'>
  //           <div style=" position: absolute; top: 230px; left: 554px; "><input id='0'></input></div>
  //           <div style=" position: absolute; top: 370px; left: 554px; "><input id='1'></input></div>
  // 				</div>
  // 		  `,
  //       answer: ["three", "that"],
  //     },
  //   ],
  // },
  3: {
    unit: "Unit 8",
    id: "4WB-U8-P59-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page59/E3/Key/E3answerKey.png",
    component: Circle_Write3,
    // checkUppercase: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: `
				Circle and write the odd one out. 
			  `,
        color: "#23408f",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 20,
        inputStyle: {
          width: 230,
          fontSize: 24,
          textAlign: "center",
          backgroundColor: "none",
          border: "none",
          height: 29,
          paddingBottom: 10,
        },
        answers: ["three", "that"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 42,
          borderRadius: "50%",
          border: "2px solid white",
          fontSize: 25,

          borderColor: "transparent",
          fontWeight: "bold",
          color: "transparent",
        },
        selectWordStyle: {
          border: "2px solid white",
          padding: "42px 28px",
          borderColor: "#00aeef",
          color: "transparent",
        },
        limitSelect: 1,
        listWords: [
          `I1111 &ensp; they1  they1`, //0
          `are22  22222  they2`, //1
        ],
        answers: ["0-8", "1-0"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative'>
      <img src='img/FriendsPlus/Page59/E3/1.jpg' style='width: 22cm; height: 11cm'>
        
        <div style=" position: absolute; top: 193px; left: 68px"><input id='0' type='Circle'/></div>
        <div style=" position: absolute; top: 318px; left: 68px"><input id='1' type='Circle'/></div>

        <div style=" position: absolute; top: 222px; left: 560px; "><input id='0'></input></div>
        <div style=" position: absolute;top: 352px; left: 560px; "><input id='1'></input></div>

        
	    </div>
      `,
    },
  },
};

export default json;
