import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 8',
		id: '4WB-U8-P58-E1',
		isHiddenCheck: true,
		audio: '',
		video: '',
		component: T6,
		inputSize: 35,
		fontSize: 30,
		maxLength: 9,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 25,
			height: 26,
			width: 390,
			letterSpacing: '29px',
		},
		textInputStyle: {
			borderBottom: 'none',
		},
		checkUppercase: true,
		exerciseKey: 'img/FriendsPlus/Page58/E1/Key/E1answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: 'Look at the picture and the letters. Write the word.',
				color: '#23408f',
			},
		],

		questions: [
			{
				title: `
					<div style=" position: relative; ">
						<div> <img src='img/FriendsPlus/Page58/E1/1.jpg' /> </div>
            <div style=" position: absolute; top: 263px; left: 281px; "><input id='0'></input></div>
						<div style=" position: absolute; top: 263px; left: 690px; "><input id='1' width='350px'></input></div>
					</div>
				`,
				answer: ['chocolate', 'neighbor'],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 8',
		id: '4WB-U8-P58-E2',
		isHiddenCheck: true,
		audio: '',
		video: '',
		component: T6,
		inputSize: 35,
		maxLength: 9,
		stylesTextInput: {
			background: 'none',
			// borderBottom: "none",
			fontSize: 22,
			height: 29,
			width: 130,
			textAlign: 'center',
		},
		textInputStyle: {
			borderBottom: 'none',
		},
		checkUppercase: true,
		exerciseKey: 'img/FriendsPlus/Page58/E2/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: 'Write.',
				color: '#23408f',
			},
		],

		questions: [
			{
				title: `
					<div style=" position: relative; width: 700px; left:10px; font-size: 22px ">
            <span>It's my dad's birthday party today ! He has lots of </span>
            <sup>1 </sup>
            <span style=" border-bottom: 2px solid black; color: gray">presents </span>
            <span>- a music </span>
            <sup>2 </sup>
            <input id='0'></input>
            <span> from our </span>
            <sup>3 </sup>
						<input id='1'></input>
            <span> and a guitar from my mom.</span>
            </br>
            <span>Dad </span>
            <sup>4 </sup>
            <input id='2'></input>
            <span> his presents a lot!</span>
            </br>
            <span>We don't have balloons. Dad doesn't like balloons because they pop.
            But we have a big cake with </span>
            <sup>5 </sup>
            <input id='3'></input>
            <span> on it, and lots of </span>
            <sup>6 </sup>
            <input id='4'></input>
            <span>!</span>
            <img src='img/FriendsPlus/Page58/E2/1.jpg' style="width: 15cm " /> 
					</div>
				`,
				answer: ['gift card', 'neighbor', 'likes', 'candles', 'chocolate'],
			},
		],
	},
}

export default json
