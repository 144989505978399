import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  4: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P91-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page91/Key/E4answerKey.png",
    inputSize: 350,
    maxLength: 40,
    checkUppercase: true,
    textAlign: "left",
    stylesTextInput: {
      // background: 'none',
      fontSize: 22,
      height: 25,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 4</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=' margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 0px 5px 0px; width: max-content; font-size:22px'>
            <div style='border-bottom:1px solid white; padding-left:20px; padding-right: 10px'><b>What do we have </b>on Mondays?&ensp; <b>We have </b>English.</div>
            <div style='border-bottom:1px solid white; padding-left:20px; padding-right: 10px'><b>Can </b>we take our bags?&ensp; Yes, you <b>can. </b>/ No, you <b>can’t.</b></div>
            <div style='padding-left:20px'>You <b>can </b>wear your shoes.</div>
          </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Order the words.</b></span>
           <div style='margin:; line-height: 60px'>
              <div><b>1. </b>Tuesdays ? we on What have do&ensp; <u style='color: gray'>What do we have on Tuesdays?</u></div>
              <div><b>2. </b>math Fridays. on We have <input id='0' width='450px'> </div>
              <div><b>3. </b>these can They T-shirts. wear <input id='1' width='420px'></div>
              <div><b>4. </b>soccer Can today ? play I <input id='2' width='475px'> </div>
           </div>
      `,
        answer: [
          `We have math on Fridays.`,
          `They can wear these T-shirts.`,
          `Can I play soccer today?`,
        ],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P91-E5",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page91/Key/E5answerKey.png",
    inputSize: 330,
    maxLength: 40,
    checkUppercase: true,
    isHiddenCheck: true,
    textAlign: "center",
    stylesTextInput: {
      // background: 'none',
      fontSize: 22,
      height: 29,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 5</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='width: fit-content; display:flex; font-size: 22px; margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 0px 30px 0px 10px'>
              <div style='margin-right: 30px; border-right: solid 2px white; padding-right:10px'>
                I<b>’m</b> going on a boat. <br>
                He<b>’s</b> surf<b>ing</b>.<br>
                We<b>’re</b> read<b>ing</b>a book.
              </div>  
              <div style=''>
                I<b>’m not </b> surf<b>ing</b> <br>
                He <b>isn’t</b> snorkel<b>ing</b>.<br>
                They <b>aren’t </b>collect<b>ing</b> shells.
              </div> 
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
           <div style='display: flex; line-height: 50px;'>
              <div style='margin-right:30px'>
                <div><b>1. </b>I’m surfing.</div>
                <div><b>2. </b>She’s snorkeling.</div>
                <div><b>3. </b>We aren’t sleeping.</div>
                <div><b>4. </b>You aren’t going on a boat.</div>
              </div>
              <div style=''>
                <div><u style='color: gray'> I’m not reading a book.</u> (not read a book)</div>
                <div># (not collect shells)</div>
                <div># (play)</div>
                <div># (surf)</div>
              </div>
           </div>
      `,
        answer: [
          `She isn't collecting shells.`,
          `We're playing.`,
          `You're surfing.`,
        ],
      },
    ],
  },
  6: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P91-E6",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page91/Key/E6answerKey.png",
    inputSize: 330,
    maxLength: 40,
    checkUppercase: true,
    isHiddenCheck: true,
    textAlign: "center",
    stylesTextInput: {
      // background: 'none',
      fontSize: 22,
      height: 29,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 6</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='display:flex; font-size: 22px; margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 0px 30px 0px 10px'>
              <div style='margin-right: 30px; border-right: solid 2px white; padding-right:10px'>
                <b>Is</b> he sleep<b>ing</b>.<br>
                Is the monkey eat<b>ing</b> the sandwich?<br>
                Are they eat<b>ing</b>?<br>
                <b>What are </b>you do<b>ing?</b>
              </div>  
              <div style=''>
                <b>Yes, </b>he <b>is.</b><br>
                <b>No, </b>it <b>isn't.</b><br>
                <b>Yes, </b>they <b>are.</b><br>
                We <b>'re</b> play<b>ing.</b>
              </div> 
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
           <div style='display: flex; line-height: 50px;'>
              <div style='margin-right:30px'>
                <div><b>1. </b>reading</div>
                <div><b>2. </b>doing</div>
                <div><b>3. </b>sleeping</div>
                <div><b>4. </b>taking photos</div>
              </div>
              <div style=''>
                <div><u style='color: gray'> Is he reading?</u> Yes, he is.</div>
                <div># We're eating mangoes.</div>
                <div># Yes, they are.</div>
                <div># No, we aren’t.</div>
              </div>
           </div>
      `,
        answer: [
          `What are you doing?`,
          `Are they sleeping?`,
          `Are you taking photos?`,
        ],
      },
    ],
  },
};

export default json;
