import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "4WB-U12-P82-E1",
    // audio: 'Audios/Track 27.mp3',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page82/E1/Key/E1answerKey.png",
    inputSize: 205,
    textAlign: "center",
    maxLength: 8,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 27,
      width: 150,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Order the letters and write the words.",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position: relative; width:100%; text-align:right' >
            <img style='width:20cm' src="img/FriendsPlus/Page82/E1/1.jpg" />    
            
            <div style=" position: absolute; top: 189px; left: 293px;">#</div>
            <div style=" position: absolute; top: 189px; left: 551px;">#</div>

            <div style=" position: absolute; top: 430px; left: 37px;">#</div>
            <div style=" position: absolute; top: 430px; left: 293px;">#</div>
            <div style=" position: absolute; top: 430px; left: 551px;">#</div>
            </div>
            
          `,
        answer: ["old", "pretty", "young", "cute", "handsome"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 12",
    id: "4WB-U12-P82-E2",
    // audio: 'Audios/Track 27.mp3',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page82/E2/Key/E2answerKey.png",
    inputSize: 205,
    textAlign: "center",
    hideBtnFooter: true,
    maxLength: 15,
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 27,
      width: 150,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Choose from the words above. Write.",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position: relative; width:100%' >
          <div style='display:flex'>
            <img style='width:13cm' src="img/FriendsPlus/Page82/E2/1.jpg" />    
            <img style='width:13cm; height:6cm' src="img/FriendsPlus/Page82/E2/2.jpg" />    
          </div>
          <div style='display:flex'>
            <div style='margin-right:40px; width:50%'>
             <div>He’s <u style='color: gray'>old</u>.</div>
             <div>He’s # .</div>
             <div>He’s # .</div>
            </div>
            <div style='width:50%'>
             <div>She’s # .</div>
             <div>She’s # .</div>
             <div>She’s # .</div>
            </div>
          </div> 

        </div>
            
          `,
        answer: ["", "", "", "", ""],
      },
    ],
  },
};

export default json;
