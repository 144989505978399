import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 10",
    id: "4WB-U10-P70-E1",
    audio: "",
    video: "",
    component: T6,
    maxLength: 100,
    exerciseKey: "img/FriendsPlus/Page70/E1/Key/E1answerKey.png",
    inputSize: 300,
    checkUppercase: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Look and number.",
        color: "#23408f",
      },
    ],
    questionImage: [],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",

      fontSize: 24,
      height: 28,
      // textTransform: 'uppercase',
      textAligh: "center",
      width: 35,
    },
    maxLength: 1,
    questions: [
      {
        title: `
		<div style=" position: relative ">
		 <img src='img/FriendsPlus/Page70/E1/1.jpg' width='95%'>

		 <div style=" position: absolute; top: 25px; left: 83px; "><input id='0'</input></div>
     <div style=" position: absolute; top: 37px; left: 422px; "><input id='1'</input></div>
		 <div style=" position: absolute; top: 153px; left: 267px; "><input id='2'</input></div>
		 <div style=" position: absolute; top: 151px; left: 525px; "><input id='3'</input></div>
     <div style=" position: absolute; top: 95px; left: 921px; "><input id='4'</input></div>
		</div>
        `,
        answer: ["4", "5", "6", "2", "3"],
      },
    ],
  },
  2: {
    unit: "Unit 10",
    id: "4WB-U10-P70-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page70/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Look at the pictures and write.
			  `,
        color: "#23408f",
      },
    ],
    hintBox: [],
    titleImage: "",
    questionImage: [],
    // inputSize: 30,
    maxLength: 40,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 28,
      width: 310,
      textAlign: "center",
    },
    textAlign: "center",
    checkUppercase: true,
    isHiddenCheck: true,
    questions: [
      {
        title: `
					<div style=" position: relative; font-size: 22px; padding-right: 80px ">
            <img src='img/FriendsPlus/Page70/E2/1.jpg' style='width: 24cm; height: 13cm'>
            <div style=" position: absolute; top: 217px; left: 462px; "><input id='0'></input> at the weekend.</div>
            <div style=" position: absolute; top: 472px; left: -6px; "><input id='1'></input> in the park.</div>
            <div style=" position: absolute; top: 472px; left: 462px; "><input id='2'></input> in the evening.</div>
					</div>
				
			  
			  `,
        answer: [
          "They like fishing",
          "They like playing basketball",
          "They like reading comics",
        ],
      },
    ],
  },
};

export default json;
