import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "4WB-U12-P87-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page87/E1/Key/E1answerKey.png",
    inputSize: 100,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 3,
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 26,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Write <i>and</i> or <i>or</i>.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <div style=' line-height:40px; font-size:23px; display: flex; width: max-content' >
          <div style='width:55%; margin-right: 40px'>
              <div><b>1.&ensp; </b> I have a teddy bear <u style='color:gray; text-align:center' >and</u> a doll.</div>
              <div><b>2.&ensp; </b>He doesn't have a kite # a bike.</div>
              <div><b>3.&ensp; </b>It’s cold  # windy.</div>
          </div>
          <div style='width:55%'>
              <div><b>4.&ensp; </b>He doesn’t like candy # cake.</div>
              <div><b>5.&ensp; </b>I like apples # pears.</div>
              <div><b>6.&ensp; </b>I play chess # I read books.</div>
          </div>
        </div>
            
          `,
        answer: ["or", "and", "or", "and", "and"],
      },
    ],
  },
  2: {
    unit: "Unit 12",
    id: "4WB-U12-P87-E2",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page43/key/E2answerKey.PNG',
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
				Circle where you were last year.
					`,
        color: "#23408f",
      },
    ],
    hideBtnFooter: true,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "10px",
          color: "",
          borderRadius: "50%",
          fontWeight: "700",
        },
        selectWordStyle: { border: "2px solid rgb(67,79,161)" },
        limitSelect: 8,
        listWords: [
          `airport beach farm park<br>
					school store museum zoo 
          `, // 0
        ],
        answers: [``],
        initialValue: {},
      },
      Layout: `
	  <div style="border: 2px solid rgb(67,79,161); border-radius: 10px">
		<div
			style="
			background-color: rgb(28, 190, 215);
			border-radius: 40px;
			margin: 10px 10px;
			"
		>
		</div> 
	  	<div style='width: 450px ; margin: 10px 10px;'>
			 <input id='0' type='Circle' style="letter-spacing: 10px;"/> 
		  </div>  
    </div>
			
				`,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 12",
    id: "4WB-U12-P87-E3",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page87/E3/Key/E3answerKey.png',
    hideBtnFooter: true,
    component: T6,
    // totalInput: 2,
    titleQuestion: [
      {
        num: "3",
        title: "Draw and write an article about a place you were at last year.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 20,
      height: 22,
      // textTransform: 'uppercase',
    },
    textareaStyle: {
      // background: 'gray',
      width: 320,
      fontSize: 21,
      height: 130,
    },
    questionImage: [],
    questions: [
      {
        title: `
        <img  style='width: 20cm' src='img/FriendsPlus/Page87/E3/1.jpg' />
        <div style='position: relative; font-size:21px'>
          <div style="position: absolute; top: -399px; left: 375px; ">Last year, I was at the <input id='0' width='100px'></div>
          <div style="position: absolute; top: -353px; left: 375px; ">It was <input id='1' width='200px'>!</div>
          <div style="position: absolute; top: -307px; left: 375px; ">I was <input id='2' width='100px'> and <input id='3' width='100px'></div>
          <div style="position: absolute; top: -260px; left: 375px; ">It wasn’t  <input id='4' width='100px'> or <input id='5' width='100px'></div>

          <div style="position: absolute; top: -224px; left: 375px; "><textarea id='6' row='3'></div>
        
        </div> 
        `,
        answer: ["", "", "", "", "", "", ""],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 12",
    id: "4WB-U12-P87-E4",
    audio: "",
    component: T6,
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page87/E1/Key/E1answerKey.png',
    inputSize: 500,
    textAlign: "left",
    isHiddenCheck: true,
    // maxLength: 3,
    textareaStyle: {
      // background: 'gray',
      width: 720,
      fontSize: 23,
      height: 250,
      paddingTop: 5,
    },
    titleQuestion: [
      {
        num: "4",
        title: "Read your friend’s article. Ask him / her a question.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <textarea id='6' row='3'>
          `,
        answer: [""],
      },
    ],
  },
};

export default json;
