import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDotsWrite from '../../components/ExcerciseTypes/LineTo/MatchDotsWrite'


const json = {
  1: {
		// Exercise num
	  unit: "Unit 4",
    id: "4WB-U4-P33-E1",
		audio: 'Audios/Track 16.mp3',
		video: '',
		component: MatchDotsWrite,
		exerciseKey: 'img/FriendsPlus/Page33/E1/Key/E1answerKey.png',
		titleQuestion: [
		  {
		   
					num: '1',
					title:
						"Write and match. Listen and check. <headphone name='16' src='Audios/Track 16.mp3'></headphone>",
					color: '#234090',
		  },
		],
		// stylesTextInput: {
		// 		background: 'none',
		// 		borderBottom: 'none',
		// 		fontSize: 23,
		// 		height: 35,
		// 		width: 40,
		// 	},
		inputSize: 50,

		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {
				  width: 40,
				  textAlign: "right",
				  fontSize: 24,
				  color: "black",
				  marginTop:"3px",
				  maxLength: 1,
				},
				isHiddenCheck:true,

				answers: ['u','v','v','t','u'],
				initialValue: [],
			  },
		  DrawLines: {
			multipleLine: false,
			boxMatch: [
			 
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '292px',
				  left: '91px',
				  width: '160px',
				  height: '120px',
				  border: 'none',
				  background: 'none',
				},
			  }, // 2
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '458px',
				  left: '91px',
				  width: '160px',
				  height: '120px',
				  border: 'none',
				  background: 'none',
				},
			  }, // 3
			  
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '127px',
				  left: '532px',
				  width: '160px',
				  height: '120px',
				  border: 'none',
				  background: 'none',
				},
			  }, // B
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '457px',
				  left: '532px',
				  width: '160px',
				  height: '120px',
				  border: 'none',
				  background: 'none',
				},
			  }, // C
			 
			 
			 
			],
			answers: ['1-2', '0-3'],
			initialValue: [],
		  },
		  Layout: `
				<headphone name='16' src=''></headphone>
	
			<div style='position: relative; width:100%; text-align:right;' >
				<img style='width:16.5cm' src="img/FriendsPlus/Page33/E1/1.jpg" />    
				
				<div style=" position: absolute; top: 348px; left: 26px; ">
				<input id='0'/></div>
				<div style=" position: absolute; top: 515px; left: 49px;">
				<input  id='1'/></div>
				<div style=" position: absolute; top: 183px; left: 495px;">
				<input  id='2'/></div>
				<div style=" position: absolute; top: 350px; left: 492px;">
				<input  id='3'/></div>
				<div style=" position: absolute; top: 516px; left: 481px;">
				<input  id='4'/></div>
				</div>  
			  <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
			
		  `,
		},
	  },
  2: {
    // Exercise num
    unit: "Unit 4",
    id: "4WB-U4-P33-E2",
    audio: "Audios/Track 17.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page33/E2/Key/E2answerKey.png",
    // inputSize: 50,
    textAlign: "center",
    maxLength: 10,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Write. Listen and chant. <headphone name='17' src='Audios/Track 17.mp3'></headphone>",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 24,
      height: 30,
      width: 180,
    },
    hintBox: [
      {
        src: [
          "tall",
          "<s style='color:gray'>tiger</s>",
          "umbrella",
          "under",
          "van",
          "vet",
        ],
        width: 750,
        borderColor: "rgb(240,128,84)",
      },
    ],
    questions: [
      {
        title: `
				<hintbox id='0' ></hintbox>
       <div style='position:relative'>
       <img src='img/FriendsPlus/Page33/E2/1.jpg' />

        <div style=" position: absolute; top: 46px; left: 297px; font-size: 24px; line-height: 50px">
				The <sup>1</sup><u style='color: gray'>&ensp;tiger&ensp;</u> tiger gets in his <sup>2</sup>#,<br>
				To visit the <sup>3</sup># <sup>4</sup># .<br>
				“Stay <sup>5</sup># your <sup>6</sup># ,<br>
				And then you won’t get wet!”
				</div>
       
     
      </div>
            
          `,
        answer: ["van", "tall", "vet", "under", "umbrella"],
      },
    ],
  },
};

export default json;
