import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  // 1: {
  //   // Exercise num
  //   unit: "Unit 8",
  //   id: "4WB-U8-P56-E1",
  //   audio: "",
  //   video: "",
  //   component: MatchDots,
  //   exerciseKey: "img/FriendsPlus/Page56/E1/Key/E1answerKey.png",
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: "Find and write the words.",
  //       color: "#2E479B",
  //     },
  //   ],
  //   question: {
  //     DrawLines: {
  //       fontWeight: "120px",
  //       multipleLine: true,
  //       boxMatch: [
  //         //Clock 2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "43px",
  //             left: "255px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "50px",
  //             left: "275px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "54px",
  //             left: "293px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "52px",
  //             left: "308px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "53px",
  //             left: "324px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "57px",
  //             left: "343px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "56px",
  //             left: "364px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//7
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "51px",
  //             left: "384px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//8
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "44px",
  //             left: "403px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "39px",
  //             left: "423px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//2

  //         //CLOCK 3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "38px",
  //             left: "444px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "35px",
  //             left: "464px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "38px",
  //             left: "485px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "36px",
  //             left: "507px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "36px",
  //             left: "528px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//7
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "43px",
  //             left: "546px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//8
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "48px",
  //             left: "568px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "54px",
  //             left: "587px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "51px",
  //             left: "608px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "59px",
  //             left: "626px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "57px",
  //             left: "642px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "57px",
  //             left: "661px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//6

  //         //	CLOCK 4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "56px",
  //             left: "680px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //3//1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "50px",
  //             left: "695px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //3//2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "46px",
  //             left: "710px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //3//3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "50px",
  //             left: "731px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //3//4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "70px",
  //             left: "735px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //3//5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "75px",
  //             left: "717px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //3//6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "79px",
  //             left: "699px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "81px",
  //             left: "682px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "83px",
  //             left: "665px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "83px",
  //             left: "642px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "87px",
  //             left: "625px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "85px",
  //             left: "604px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//7
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "79px",
  //             left: "584px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//8
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "70px",
  //             left: "562px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "66px",
  //             left: "541px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//2

  //         //CLOCK 3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "63px",
  //             left: "522px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "63px",
  //             left: "507px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "60px",
  //             left: "484px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "61px",
  //             left: "464px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "63px",
  //             left: "447px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//7
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "65px",
  //             left: "427px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//8
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "70px",
  //             left: "411px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "77px",
  //             left: "390px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "78px",
  //             left: "367px",
  //             width: 5,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "79px",
  //             left: "346px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "79px",
  //             left: "327px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "81px",
  //             left: "308px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//6

  //         //	CLOCK 4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "81px",
  //             left: "291px",
  //             width: 6,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //3//1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "76px",
  //             left: "270px",
  //             width: 6,
  //             height: 6,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //3//2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "71px",
  //             left: "248px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //3//3
  //       ],
  //       answers: ['0-51', '0-1', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '44-7', '44-45', '45-46', '46-47', '47-48', '48-49', '49-50', '50-51', '7-8', '8-9', '10-9', '10-11', '11-12', '12-13', '38-39', '39-40', '40-41', '41-42', '42-43', '43-44', '13-14', '14-37', '37-38', '36-37', '35-36', '34-35', '33-34', '32-33', '19-32', '18-19', '17-18', '16-17', '15-16', '14-15', '19-20', '20-21', '21-22', '22-23', '23-24', '24-25', '25-26', '26-27', '27-28', '28-29', '29-30', '30-31', '31-32'],
  //       initialValue: [],
  //     },
  //     Layout: `
  //     <img style='width: 23cm; margin-bottom:50px' src="img/FriendsPlus/Page56/E1/1.jpg" />
  //         <input id='0' type='boxMatch' />
  //         <input id='1' type='boxMatch' />
  //         <input id='2' type='boxMatch' />
  //         <input id='3' type='boxMatch' />
  //         <input id='4' type='boxMatch' />
  //         <input id='5' type='boxMatch' />
  //         <input id='6' type='boxMatch' />
  //         <input id='7' type='boxMatch' />
  //         <input id='8' type='boxMatch' />
  //         <input id='9' type='boxMatch' />
  //         <input id='10' type='boxMatch' />
  //         <input id='11' type='boxMatch' />

  // 				<input id='12' type='boxMatch' />
  // 				<input id='13' type='boxMatch' />
  // 				<input id='14' type='boxMatch' />
  // 				<input id='15' type='boxMatch' />
  // 				<input id='16' type='boxMatch' />
  // 				<input id='17' type='boxMatch' />
  // 				<input id='18' type='boxMatch' />
  // 				<input id='19' type='boxMatch' />
  // 				<input id='20' type='boxMatch' />
  // 				<input id='21' type='boxMatch' />
  // 				<input id='22' type='boxMatch' />
  // 				<input id='23' type='boxMatch' />

  // 				<input id='24' type='boxMatch' />
  // 				<input id='25' type='boxMatch' />
  // 				<input id='26' type='boxMatch' />
  // 				<input id='27' type='boxMatch' />
  //         <input id='28' type='boxMatch' />
  //         <input id='29' type='boxMatch' />
  //         <input id='30' type='boxMatch' />
  //         <input id='31' type='boxMatch' />
  //         <input id='32' type='boxMatch' />
  //         <input id='33' type='boxMatch' />
  //         <input id='34' type='boxMatch' />
  //         <input id='35' type='boxMatch' />
  //         <input id='36' type='boxMatch' />
  //         <input id='37' type='boxMatch' />
  //         <input id='38' type='boxMatch' />

  // 				<input id='39' type='boxMatch' />
  // 				<input id='40' type='boxMatch' />
  // 				<input id='41' type='boxMatch' />
  // 				<input id='42' type='boxMatch' />
  // 				<input id='43' type='boxMatch' />
  // 				<input id='44' type='boxMatch' />
  // 				<input id='45' type='boxMatch' />
  // 				<input id='46' type='boxMatch' />
  // 				<input id='47' type='boxMatch' />
  // 				<input id='48' type='boxMatch' />
  // 				<input id='49' type='boxMatch' />
  // 				<input id='50' type='boxMatch' />

  // 				<input id='51' type='boxMatch' />
  //     `,
  //   },
  // },
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "4WB-U8-P56-E1",
    audio: "",
    // hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page56/E1/Key/E1answerKey.png",
    component: T6,
    inputSize: 550,
    maxLength: 100,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: "uppercase",
      borderStyle: "2px dotted",
      textAlign: "left",
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Find and write the words.",
        color: "#234090",
      },
    ],
    questions: [
      {
        title: `

            <div> <img style='width:22cm' src='img/FriendsPlus/Page56/E1/1.jpg' /> </div>
  			<div>
  			<i style='font-size:18px; color: rgb(245,54,92)'>*Write the selected words in the sorted order of the list above and between words seperated by commas (,).</i></div>
        <div style='display: flex; margin-top: 30px'>
        <b>Answer:&ensp;</b><i style='color: gray'>candle,</i>#

        </div>
          `,
        answer: [
          "balloon, present, candy, invite / balloon, candy, present, invite / balloon, invite, candy, present / balloon, candy, invite, present",
          "present, candy, invite, balloon / present, invite, candy, balloon / present, balloon, candy, invite/ present, candy, balloon, invite",
          "candy, invite, balloon, present / candy, balloon, invite, present / candy, present, invite, balloon/ candy, invite, present, balloon",
          "invite, balloon, present, candy / invite, present, balloon, candy / invite, candy, present, balloon/ invite, present, candy balloon",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 8",
    id: "4WB-U8-P56-E2",
    audio: "Audios/Track 30.mp3",
    video: "",
    // component: T6,
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page56/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and match. There are two extra pictures. <headphone name='30' src='Audios/Track 30.mp3'></headphone>",
        color: "#2E479B",
      },
    ],
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          //mid
          {
            boxMatchStyle: {
              position: "absolute",
              top: "261px",
              left: "273px",
              width: 76,
              height: 26,
              //backgroundColor: 'blue',
              // border: "1px solid",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "300px",
              left: "273px",
              width: 76,
              height: 26,
              //backgroundColor: 'red',
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "337px",
              left: "279px",
              width: 88,
              height: 26,
              //backgroundColor: 'yellow',
              // border: "1px solid",
            },
          }, //2
          //left
          {
            boxMatchStyle: {
              position: "absolute",
              top: "112px",
              left: "82px",
              width: 137,
              height: 151,
              //backgroundColor: 'pink',
              // border: "1px solid",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "281px",
              left: "85px",
              width: 137,
              height: 151,
              //backgroundColor: 'blue',
              // border: "1px solid",
            },
          }, //4
          //right
          {
            boxMatchStyle: {
              position: "absolute",
              top: "108px",
              left: "528px",
              width: 168,
              height: 118,
              //backgroundColor: 'red',
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "281px",
              left: "528px",
              width: 168,
              height: 123,
              //backgroundColor: 'yellow',
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "458px",
              left: "528px",
              width: 168,
              height: 118,
              //backgroundColor: 'black',
              // border: "1px solid",
            },
          }, //7
        ],
        answers: ["0-7", "1-6", "2-3"],
        initialValue: [],
      },
      Layout: `
      <img style='padding-top:30px;margin-bottom: 30px; width: 17cm; height: 15cm' src="img/FriendsPlus/Page56/E2/1.jpg" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />

          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />

          
      `,
    },
  },
};

export default json;
