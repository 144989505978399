import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "4WB-U2-P20-E1",
    audio: "",
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page7/Key/E1answerKey.png',
    component: T6,
    inputSize: 250,
    textAlign: "center",
    maxLength: 18,

    titleQuestion: [
      {
        num: "1",
        title: "Read.",
        color: "#234090",
      },
    ],
    questions: [
      {
        title: `	
       
            <div> <img style='width:22cm' src='img/FriendsPlus/Page20/E1/1.jpg' /> </div>
          `,
        answer: [""],
      },
    ],
  },
  2: {
    unit: "Unit 2",
    id: "4WB-U2-P20-E2",
    exerciseKey: "img/FriendsPlus/Page20/E2/Key/E2answerKey.PNG",
    video: "",
    // audio: 'Audios/2.12.mp3',
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `Read again. Circle two clothes words.`,
        color: "#234090",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 149,
          fontSize: 23,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: [""],
        initialValue: [],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "none",
          borderRadius: "50%",
          borderColor: "transparent",
          // color: "#00aeef",
        },
        selectWordStyle: {
          padding: 5,
          border: "solid 2px",
          borderColor: "#00aeef",
        },
        limitSelect: 2,
        listWords: [
          "I’m Jason. That’s my dad. He works in an office. He wears black pants and a shirt. <br> In his office, my dad has a big table. He has a computer and a picture on his table. <br> He goes home and we have dinner together.", //0
        ],
        answers: ["0-26", "0-32"],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative'>
          <img style='width: 20cm; margin-left: 34px' src='img/FriendsPlus/Page20/E2/1.jpg' />
          <div style=" position: absolute; top: 62px; left: 112px; width:11cm"><input id='0'  type='Circle' /></div>
          
        </div> 
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 2",
    id: "4WB-U2-P20-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page20/E3/Key/E3answerKey.png",

    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: "3",
        title: " Read again. Fill in the correct circle.",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page20/E3/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page20/E3/2.jpg" },
        { url: "img/FriendsPlus/Page20/E3/3.jpg" },
        { url: "img/FriendsPlus/Page20/E3/4.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page20/E3/5.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page20/E3/6.jpg" }],
      [
        { url: "img/FriendsPlus/Page20/E3/7.jpg" },
        { url: "img/FriendsPlus/Page20/E3/8.jpg" },
        { url: "img/FriendsPlus/Page20/E3/9.jpg" },
        { url: "img/FriendsPlus/Page20/E3/10.jpg", input: 1 },
        { url: "img/FriendsPlus/Page20/E3/11.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page20/E3/12.jpg" }],
      [
        { url: "img/FriendsPlus/Page20/E3/13.jpg" },
        { url: "img/FriendsPlus/Page20/E3/14.jpg", input: 3 },
        { url: "img/FriendsPlus/Page20/E3/15.jpg" },
        { url: "img/FriendsPlus/Page20/E3/16.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page20/E3/17.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page20/E3/18.jpg" }],
      [
        { url: "img/FriendsPlus/Page20/E3/19.jpg" },
        { url: "img/FriendsPlus/Page20/E3/20.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page20/E3/21.jpg" },
        { url: "img/FriendsPlus/Page20/E3/22.jpg", input: 2 },
        { url: "img/FriendsPlus/Page20/E3/23.jpg" },
      ],
    ],
  },
};

export default json;
