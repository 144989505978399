import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "4WB-U11-P77-E1",
    audio: "Audios/Track 38.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page77/E1/Key/E1answerKey.png",

    component: DesignUnderLine,
    totalInput: 3,
    titleQuestion: [
      {
        num: 1,
        title:
          "Listen and check (✓) the box. <headphone name='38' src='Audios/Track 38.mp3'></headphone>",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row 1
      [{ url: "img/FriendsPlus/Page77/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page77/E1/2.jpg" },
        { url: "img/FriendsPlus/Page77/E1/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page77/E1/4.jpg" },
        { url: "img/FriendsPlus/Page77/E1/5.jpg", input: 1 },
        { url: "img/FriendsPlus/Page77/E1/6.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page77/E1/7.jpg" }],
      [
        { url: "img/FriendsPlus/Page77/E1/8.jpg" },
        { url: "img/FriendsPlus/Page77/E1/9.jpg", input: 2 },
        { url: "img/FriendsPlus/Page77/E1/10.jpg" },
        { url: "img/FriendsPlus/Page77/E1/11.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page77/E1/12.jpg" },
        { url: "img/FriendsPlus/Page77/E1/13.jpg", input: 3 },
        { url: "img/FriendsPlus/Page77/E1/14.jpg" },
        { url: "img/FriendsPlus/Page77/E1/15.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page77/E1/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page77/E1/17.jpg" }],
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 11",
    id: "4WB-U11-P77-E2",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page77/E2/Key/E2answerKey.png",
    inputSize: 40,
    textAlign: "left",
    maxLength: 100,
    checkUppercase: true,
    stylesTextInput: {
      background: "none",
      fontSize: 23,
      height: 30,
      width: 700,
      // borderBottom: 'none',
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Look at the map. Use the words in the box to write four directions to get to the school.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "next to",
          "on the right",
          "on the left",
          "opposite",
          "straight",
          "turn right",
          "turn left",
        ],
        width: 850,
        borderColor: "rgb(159,171,205)",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <hintbox id='0' style='border-radius:2px'></hintbox>
        <div style=''>
        <img style='width:19cm' src="img/FriendsPlus/Page77/E2/1.jpg"/>
          <div><b>1&ensp; </b><u style='color:gray'>&ensp; Go straight.&ensp;</u></div>
          <div><b>2&ensp; </b># .</div>
          <div><b>3&ensp; </b># .</div>
          <div><b>4&ensp; </b># .</div>
          <div><b>5&ensp; </b># .</div>
        </div>   
          `,
        answer: [
          "Turn left at the traffic lights",
          "Turn right",
          "The school is on the left",
          `It's opposite the library`,
        ],
      },
    ],
  },
};

export default json;
