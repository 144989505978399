import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import TB2 from "../../components/ExcerciseTypes/Table/TB2";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Review 4",
    id: "4WB-R4-P88-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page88/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Match.",
        color: "#2E479B",
      },
    ],
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          //left
          {
            boxMatchStyle: {
              position: "absolute",
              top: "138px",
              left: "75px",
              width: 91,
              height: 36,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "185px",
              left: "75px",
              width: 91,
              height: 36,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "239px",
              left: "75px",
              width: 91,
              height: 36,
              //backgroundColor: 'rgb(67,79,161)',
              //border: '2px solid',
            },
          }, //2
          //right
          {
            boxMatchStyle: {
              position: "absolute",
              top: "78px",
              left: "416px",
              width: 182,
              height: 135,
              //backgroundColor: 'pink',
              //border: '2px solid',
            },
          }, //2

          {
            boxMatchStyle: {
              position: "absolute",
              top: "249px",
              left: "416px",
              width: 182,
              height: 135,
              //backgroundColor: 'pink',
              //border: '2px solid',
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "249px",
              left: "642px",
              width: 182,
              height: 135,
              //backgroundColor: 'pink',
              //border: '2px solid',
            },
          }, //4
        ],
        answers: ["0-5", "1-3", "2-4"],
        initialValue: [],
      },
      Layout: `
      <img style='width: 20cm; margin-bottom:50px' src="img/FriendsPlus/Page88/E1/1.jpg" />
          <input id='0' type='boxMatch' />
          <input id='1' type='boxMatch' />
          <input id='2' type='boxMatch' />

          <input id='3' type='boxMatch' />
          <input id='4' type='boxMatch' />
          <input id='5' type='boxMatch' /> 
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Review 4",
    id: "4WB-R4-P88-E2",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page88/E2/Key/E2answerKey.png",
    inputSize: 200,
    textAlign: "center",
    maxLength: 20,
    isHiddenCheck: true,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 25,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: "2",
        title: "Write the words in the correct boxes.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "<s style='color:gray'>cute</s>",
          "fish",
          "turn right",
          "go straight",
          "handsome",
          "play chess",
          "pretty",
          "it’s next to",
          "read comics",
          "turn left",
          "smart",
          "play volleyball",
        ],
        width: 800,
        borderColor: "rgb(35,64,144)",
      },
    ],
    questions: [
      {
        title: `
        <div style='position:relative;'></div>
        <hintbox id='0' ></hintbox>
        <div style='display:flex; position: relative'>
          <div style='width:164px; font-size:20px; border-radius:5px; text-align:center ;background-color:rgb(35,64,144); border:none; color:white; padding:6px; position:absolute; top: 21px ;left:54px'>adjectives to describe people</div>
          <div style='width:fit-content; font-size:20px; border-radius:5px; text-align:center ;background-color:rgb(35,64,144); border:none; color:white; padding:6px; position:absolute; top: 39px ;left:358px'>hobbies</div>
          <div style='width:fit-content; font-size:20px; border-radius:5px; text-align:center ;background-color:rgb(35,64,144); border:none; color:white; padding:6px; position:absolute; top: 39px ;left:619px'>directions</div>
        </div> 
            
         

        <div style='display:flex;margin-top:40px; line-height:50px; text-align:center' >
        <div style='width:40%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div><u style='color:gray'>&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;cute&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> </div>
              <div> # </div>
              <div> # </div>  
              <div> # </div>
          </div>
          <div style='width:40%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>  
              <div> # </div>
              <div> # </div>  
              <div> # </div>
          </div>
          
          <div style='width:40%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>
              <div> # </div>
              <div> # </div>  
              <div> # </div>
          </div>
        </div>
            
          `,
        answer: [
          "handsome/pretty/smart",
          "handsome/pretty/smart",
          "handsome/pretty/smart",
          "fish/play chess/read comics/play volleyball",
          "fish/play chess/read comics/play volleyball",
          "fish/play chess/read comics/play volleyball",
          "fish/play chess/read comics/play volleyball",
          `turn right/go straight/it's next to/turn left`,
          `turn right/go straight/it's next to/turn left`,
          `turn right/go straight/it's next to/turn left`,
          `turn right/go straight/it's next to/turn left`,
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Review 4",
    id: "4WB-R4-P88-E3",
    // audio: 'Audios/Track 35.mp3',
    component: T6,
    exerciseKey: "img/FriendsPlus/Page88/E3/Key/E3answerKey.png",
    inputSize: 200,
    textAlign: "center",
    maxLength: 15,
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 30,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: "3",
        title: "Write.",
        color: "#234090",
      },
    ],
    questions: [
      {
        title: `
      <div style='line-height:50px'>
        <div><b>1. </b>She wasn’t happy. She <u style='color:gray'>was</u> sad.</div>
        <div><b>2. </b>They were dry. They # wet.</div>
        <div><b>3. </b>He was hot. He # cold.</div>
        <div><b>4. </b>I wasn’t at the beach. I # in the swimming pool.</div>
      </div>
            
          `,
        answer: [`weren't`, `wasn't`, `was`],
      },
    ],
  },
};

export default json;
