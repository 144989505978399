import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P14-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page14/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Match the pictures with the sentences. There are two extra sentences. ",
        color: "#2E479B",
      },
    ],
    question: {
      DrawLines: {
        // multipleLine: true,
        boxMatch: [
          //left
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "101px",
          //     left: "101px",
          //     width: 130,
          //     height: 137,
          //     // backgroundColor: "red",
          //     // border: "1px solid",
          //   },
          // }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "249px",
              left: "101px",
              width: 130,
              height: 143,
              // backgroundColor: "blue",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "398px",
              left: "101px",
              width: 130,
              height: 137,
              // backgroundColor: "red",
              // border: "1px solid",
            },
          }, //2
          //right
          {
            boxMatchStyle: {
              position: "absolute",
              top: "102px",
              left: "650px",
              width: 126,
              height: 132,
              // backgroundColor: "yellow",
              // border: "1px solid",
            },
          }, //3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "253px",
              left: "650px",
              width: 126,
              height: 144,
              // backgroundColor: "blue",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "398px",
              left: "650px",
              width: 126,
              height: 144,
              // backgroundColor: "blue",
              // border: "1px solid",
            },
          }, //5
          //mid
          {
            boxMatchStyle: {
              position: "absolute",
              top: "61px",
              left: "360px",
              width: 370,
              height: 44,
              // backgroundColor: "gray",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "114px",
              left: "360px",
              width: 233,
              height: 44,
              // backgroundColor: "black",
              // border: "1px solid",
            },
          }, //7
          {
            boxMatchStyle: {
              position: "absolute",
              top: "167px",
              left: "360px",
              width: 336,
              height: 44,
              // backgroundColor: "white",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "220px",
              left: "360px",
              width: 324,
              height: 44,
              // backgroundColor: "white",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "273px",
              left: "360px",
              width: 317,
              height: 44,
              // backgroundColor: "white",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "326px",
              left: "360px",
              width: 370,
              height: 44,
              // backgroundColor: "white",
              // border: "1px solid",
            },
          }, //11
          {
            boxMatchStyle: {
              position: "absolute",
              top: "379px",
              left: "360px",
              width: 154,
              height: 44,
              // backgroundColor: "white",
              // border: "1px solid",
            },
          }, //12
          // {
          //   boxMatchStyle: {
          //     position: "absolute",
          //     top: "432px",
          //     left: "360px",
          //     width: 267,
          //     height: 44,
          //     // backgroundColor: "white",
          //     // border: "1px solid",
          //   },
          // }, //13
        ],
        answers: ["0-5", "1-10", "2-9", "3-8", "4-6"],
        initialValue: [],
      },
      Layout: `
      <img style='padding-top:30px; width:19cm; margin-bottom:50px' src="img/FriendsPlus/Page14/E1/1.jpg" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />

          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />

          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P14-E2",
    audio: "Audios/Track 06.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page14/E2/Key/E2answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 40,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 25,
      height: 25,
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and number. <headphone name='06' src='Audios/Track 06.mp3'></headphone> ",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <div style='position:relative'>
            <img style='padding-top:30px' src="img/FriendsPlus/Page14/E2/1.jpg" />
          <div style='width:112%; padding-right:89px'>
            <div style="background-color:#234090; position: absolute; top: 144px; left: 114px">#</div>
            <div style="background-color:#234090; position: absolute; top: 144px; left: 624px">#</div>
            <div style="background-color:#234090; position: absolute; top: 144px; left: 880px">#</div>
           
          </div>
        </div>
       
            
          `,
        answer: ["3", "2", "4"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P14-E3",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page14/E3/Key/E3answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 150,
    textAlign: "center",
    maxLength: 15,

    titleQuestion: [
      {
        num: "3",
        title: "Write the words in the correct box.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "thirteen",
          "<s style='color:gray'>mom</s>",
          "Harry",
          "firefighter",
          "grandpa",
          "doctor",
          "Milly",
          "nine",
        ],
        width: 1000,
        borderColor: "rgb(101,208,151)",
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0' ></hintbox>
        <div style='display:flex; position: absolute; top: 153px'>
            <div style='width:25%; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(218,235,194); border:none; color:rgb(32,174,92); padding:6px '>family</div>
            <div style='width:25%; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(218,235,194); border:none; color:rgb(32,174,92); padding:6px;margin-left:170px  '>jobs</div>
            <div style='width:25%; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(218,235,194); border:none; color:rgb(32,174,92); padding:6px ;margin-left:150px  '>numbers</div>
            <div style='width:25%; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(218,235,194); border:none; color:rgb(32,174,92); padding:6px ;margin-left:140px  '>names</div>
        </div> 

        <div style='display:flex;margin-top:40px; line-height:50px; text-align:center' >
          <div style='width:25%; border: 1px solid rgb(202, 202, 202); border-radius:20px; padding-top:40px; margin:10px; padding-bottom:20px'>
              <div><span style='color:gray; border-bottom:2px solid rgb(202, 202, 202); display:inline-block; width:150px ' >&ensp;mom&ensp;</div>
              <div> # </div>
          </div>
          <div style='width:25%; border: 1px solid rgb(202, 202, 202); border-radius:20px; padding-top:40px; margin:10px; padding-bottom:20px''>
              <div> # </div>
              <div> # </div>
          </div>
          <div style='width:25%; border: 1px solid rgb(202, 202, 202); border-radius:20px; padding-top:40px; margin:10px; padding-bottom:20px''>
              <div> # </div>
              <div> # </div>
          </div>
          <div style='width:25%; border: 1px solid rgb(202, 202, 202); border-radius:20px; padding-top:40px; margin:10px; padding-bottom:20px''>
              <div> # </div>
              <div> # </div>
          </div>
        </div>
            
          `,
        answer: [
          "grandpa",
          "firefighter",
          "doctor",
          "thirteen",
          "nine",
          "Harry",
          "Milly",
        ],
      },
    ],
  },
};

export default json;
