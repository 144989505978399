import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import CreateImageCode from "../../components/ExcerciseTypes/Design/CreateImageCode";

const json = {
  1: {
    unit: "Unit 10",
    id: "4WB-U10-P75-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page75/E1/Key/E1answerKey.PNG",
    component: Circle_Write,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "1",
        title: "Underline the full form. Write the short form.",
        color: "#234090",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 8,
        inputStyle: { width: 100, fontSize: 23, textAlign: "center" },
        answers: [`don't`, `don't`, `don't`, `doesn't`, `He's`],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: '50%',
          fontWeight: "bold",
          borderColor: "transparent",
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: "solid 2px",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `I do_not have a sister`, //0
          `We do_not like playing volleyball.`, //1
          `I do_not have two cousins.`, //2
          `She does_not have a comic`, //3
          `He_is from Brazil.`, //4
        ],
        answers: ['0-2', '1-2', '2-2', '3-2', '4-0'],
        initialValue: [],
      },
      Layout: `
      <div style='display: flex; margin-top: 20px'>
        <div style='display: flex; margin-right: 30px'>
          <div style='margin-right:20px'>
            <div><b>1. </b><u>It is </u>hot in summer.</div>
            <div><b>2. </b><input id='0' type='Circle'/></div>
            <div><b>3. </b><input id='1' type='Circle'/></div>
          </div>
          <div>
            <div><u style='color: gray'>&ensp;It's&ensp;</u></div>
            <div><input id='0' ></input></div>
            <div><input id='1' ></input></div>
          </div>
        </div>
        <div style='display: flex; margin-bottom:50px'>
          <div style='margin-right:20px'>
            <div><b>4. </b><input id='2' type='Circle'/></div>
            <div><b>5. </b><input id='3' type='Circle'/></div>
            <div><b>6. </b><input id='4' type='Circle'/></div>
          </div>
          <div>
            <div><input id='2' ></input></div>
            <div><input id='3' ></input></div>
            <div><input id='4' ></input></div>
          </div>
        </div>
        
         
	    </div>
      `,
    },
  },
  2: {
    unit: "Unit 10",
    id: "4WB-U10-P75-E2",
    exerciseKey: "img/FriendsPlus/Page75/E2/Key/E2answerKey.PNG",
    video: "",
    // audio: 'Audios/2.12.mp3',
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: ` Read the online pen pal profiles and underline the short forms.`,
        color: "#234090",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 11,
        inputStyle: {
          width: 149,
          fontSize: 22,
          borderBottom: "none",
          textAlign: "center",
        },
        answers: [""],
        initialValue: [],
      },

      Circle: {
        initialWordStyle: {
          padding: 0,
          // borderRadius: "50%",
          borderColor: "transparent",
        },
        selectWordStyle: {
          // padding: 5,
          borderBottom: "solid 2px",
          borderColor: "#00aeef",
        },
        limitSelect: 4,
        listWords: [
          "Hi! I'm Felipe.<br> I'm from Brazil. I like playing the guitar! I don't like shopping, but I like playing badminton.<br> Email me at<br> felipe@mailme.com. Write soon!", //0
          "Hello! My name's Kelly.<br> I'm from the U.S.A. I like visiting family and fishing with my grandma.<br> I want to be your pen pal. Please email me at<br> kelly@mailme.com. Bye!",
        ],
        answers: ["0-2", "0-6", "0-24", "1-8", "1-4"],
        initialValue: [],
      },
      Layout: `
        <div style='position: relative; font-size: 21px'>
          <img style='width: 26cm; height: 14cm' src='img/FriendsPlus/Page75/E2/1.jpg' />
          <div style=" position: absolute; top: 148px; left: 37px; width:7.5cm"><input id='0'  type='Circle' /></div>
          <div style=" position: absolute; top: 161px; left: 496px; width:7.5cm"><input id='1'  type='Circle' /></div>
          
        </div> 
      `,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 10",
    id: "4WB-U10-P75-E2",
    audio: "",
    video: "",
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page67/E3/Key/E3answerKey.png",
    component: T6,
    // totalInput: 2,
    titleQuestion: [
      {
        num: "3",
        title: "Draw your hobbies and write an online pen pal profile.",
        color: "#234090",
      },
    ],
    selectStyle: {
      height: 35,
      width: 35,
      backgroundColor: "none",
      border: "none",
      textAlign: "center",
    },
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 27,
      // textTransform: 'uppercase',
    },
    textareaStyle: {
      // background: 'gray',
      width: 315,
      fontSize: 21,
      height: 70,
    },
    questionImage: [],
    questions: [
      {
        title: `
        <img  style='width: 22cm' src='img/FriendsPlus/Page75/E3/1.jpg' />
        <div style='position: relative; font-size:21px'>
          <div style='position:absolute; top:-351px ;left: 579px'><input id='0' width='200px'></div>
          <div style="position: absolute; top: -308px; left: 475px; "><textarea id='2' row='3'></div>
          <div style="position: absolute; top: -194px; left: 475px; "><textarea id='3' row='3' height='50px'></div>          
          <div style='position:absolute; top: -145px ;left: 485px'><input id='1' width='290px'></div>
        </div> 
        `,
        answer: ["", "", "", ""],
      },
    ],
  },
  4: {
    unit: "Unit 10",
    id: "4WB-U10-P75-E2",
    audio: "",
    video: "",
    hideBtnFooter: true,
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "4",
        title: "Talk about your profile with a friend.",
        color: "#234090",
        // prefix: { icons: ["far fa-comment"] },
      },
    ],
    questionImage: [],
  },
};

export default json;
