import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'

const json = {
	1: {
		unit: 'Unit 6',
		id: '4WB-U6-P45-E1',
		audio: 'Audios/Track 24.mp3',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page45/E1/Key/E1answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `
				Write. Listen and check. <headphone name='24' src='Audios/Track 24.mp3'></headphone> 
			  `,
				color: '#234090',
			},
		],
		titleImage: '',
		questionImage: [],
		inputSize: 50,
		maxLength: 1,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 21,
			height: 44,
			// textTransform: 'uppercase',
			width: 40,
		},
		textAlign: 'center',
		isHiddenCheck: true,
		hintBox: [
			{
				src: ['y', 'z'],
				width: 150,
				borderColor: 'rgb(238,61,60)',
				fontSize: 22,
			},
		],
		questions: [
			{
				title: `
					<div style=" position: relative ">
          <hintbox id='0' ></hintbox>
            <img src='img/FriendsPlus/Page45/E1/1.jpg' style='width: 14.5cm; height:13cm'>
            
            <div style=" position: absolute; top: 183px; left: 371px; "><input id='0'></input></div>
            <div style=" position: absolute; top: 343px; left: 62px; "><input id='1'></input></div>
            <div style=" position: absolute; top: 343px; left: 371px; "><input id='2'></input></div>
            <div style=" position: absolute; top: 508px; left: 62px; "><input id='3'></input></div>
            <div style=" position: absolute; top: 509px; left: 371px; "><input id='4'></input></div>
					</div>
				
			  
			  `,
				answer: ['z', 'y', 'z', 'y', 'z'],
			},
		],
	},
	2: {
		unit: 'Unit 6',
		id: '4WB-U6-P45-E2',
		audio: 'Audios/Track 25.mp3',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page45/E2/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `
				Write. Listen and chant. <headphone name='25' src='Audios/Track 25.mp3'></headphone>
			  `,
				color: '#234090',
			},
		],
		titleImage: '',
		questionImage: [],
		inputSize: 50,
		maxLength: 6,
		stylesTextInput: {
			background: 'none',
			// borderBottom: "none",
			fontSize: 23,
			height: 29,
			// textTransform: 'uppercase',
			width: 130,
		},
		textAlign: 'center',
		isHiddenCheck: true,
		hintBox: [
			{
				src: ['yogurt', 'yo-yo', 'zebra', 'zoo'],
				width: 450,
				borderColor: 'rgb(238,61,60)',
				fontSize: 22,
			},
		],
		questions: [
			{
				title: `
        <hintbox id='0' ></hintbox>
					<div style=" position: relative; margin-top:20px; margin-bottom: 300px ">
            <img src='img/FriendsPlus/Page45/E2/1.jpg'>
            <div style=" margin-top: -240px; margin-left: 220px">
             <div style=" position: absolute; margin-top:0px"><span>I can see a </span><sup>1</sup><input id='0'></input><span>.</span></div>
             <div style=" position: absolute; margin-top:50px"><span>I can see the </span><sup>2</sup><input id='1'></input><span>.</span></div>
             <div style=" position: absolute; margin-top:100px"><span>There's a </span><sup>3</sup><input id='2'></input><span>in the </span><sup>4</sup><input id='3'></input><span>.</span></div>
             <div style=" position: absolute; margin-top:150px"><span>There's a </span><sup>5</sup><input id='4'></input><span>in the </span><sup>6</sup><input id='5'></input><span>.</span></div>
            </div>
					</div>
				
			  
			  `,
				answer: ['yogurt', 'zoo', 'yo-yo', 'yogurt', 'zebra', 'zoo'],
			},
		],
	},
}

export default json
