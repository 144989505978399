import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import PaintColorType from '../../components/ExcerciseTypes/Design/PaintColorType'
const json = {
	1: {
		unit: 'Unit 6',
		id: '4WB-U6-P43-E1',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page43/E1/Key/E1answerKey.png',
		titleQuestion: [
			{
				num: '1',
				title: `
				Write the answers. 
			  `,
				color: '#234090',
			},
		],
		titleImage: '',
		questionImage: [],
		inputSize: 26,
		checkUppercase: true,
		maxLength: 16,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 23,
			height: 26,
			// textTransform: 'uppercase',
			width: 190,
		},
		textAlign: 'center',
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style=" position: relative ">
            <img src='img/FriendsPlus/Page43/E1/1.jpg' style='width: 21cm'>
            
            <div style=" position: absolute; top: 195px; left: 580px; "><input id='0'></input></div>
            <div style=" position: absolute; top: 446px; left: 137px; "><input id='1'></input></div>
            <div style=" position: absolute; top: 443px; left: 532px; "><input id='2' width=240px></input></div>
			  
			  `,
				answer: ['Yes, I am.', "No, I'm not.", 'Yes, we are.'],
			},
		],
	},
	2: {
		unit: 'Unit 6',
		id: '4WB-U6-P43-E2',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page43/E2/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `Write.`,
				color: '#234090',
			},
		],
		titleImage: '',
		questionImage: [],
		inputSize: 50,
		stylesTextInput: {
			background: 'none',
			// borderBottom: "none",
			fontSize: 23,
			height: 29,
			// textTransform: 'uppercase',
			width: 170,
		},
		maxLength: 20,
		textAlign: 'center',
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style=" display: flex ">
            <div>
             <b>Sam: </b>
             <span>Look at the monkeys!</span></br>
             <b>Vinh: </b>
             <span>Are</span> <sup>1</sup><input id='0'></input><span>?</span></br>
             <b>Sam: </b>
             <span>No, they aren’t eating.</span></br>
             <b>Vinh: </b>
             <span>What</span> <sup>2</sup><input id='1'></input><span>?</span></br>
             <b>Sam: </b>
             <span>I’m taking photos.</span></br>
             <b>Vinh: </b>
             <span>Look at the kangaroo!</span></br>
             <span style='margin-left:67px'>Is</span> <sup>3</sup><input id='2'></input><span>?</span></br>
             <b>Sam: </b>
             <span>No, it isn’t playing.</span></br>
             <b>Vinh: </b>
             <span>What</span> <sup>4</sup><input id='3'></input><span>?</span></br>
             <b>Sam: </b>
             <span>It’s jumping. </span></br>
            </div>
            <img src='img/FriendsPlus/Page43/E2/1.jpg' width='50%' style='margin-left:60px'>
          </div>
			  `,
				answer: ['they eating', 'are you doing', 'it playing', 'is it doing'],
			},
		],
	},
}

export default json
