import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write3 from "../../components/ExcerciseTypes/Circle_Write3";

const json = {
  4: {
    unit: "Review 2",
    id: "4WB-R2-P49-E4",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page49/E4/Key/E4answerKey.png",
    titleQuestion: [
      {
        num: "4",
        title: `
				Write.
			  `,
        color: "rgb(240,93,36)",
      },
    ],
    hintBox: [
      {
        src: ["Can we", "Is she", "<s>What do we</s>", "Are you"],
        borderColor: "#f25822",
        width: 630,
        textAlign: "center",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 50,
    maxLength: 7,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 27,
      // textTransform: 'uppercase',
      width: 180,
    },
    textAlign: "center",
    isHiddenCheck: true,
    checkUppercase: true,
    questions: [
      {
        title: `
					<div style=" position: relative">
          <hintbox id='0'></hintbox>
            <img src='img/FriendsPlus/Page49/E4/1.jpg' style='width: 23cm'>
  
            <div style=" position: absolute; top: 214px; left: 215px; "><input id='0'></input></div>
            <div style=" position: absolute; top: 79px; left: 677px; "><input id='1'></input></div>
            <div style=" position: absolute; top: 225px; left: 677px; "><input id='2'></input></div>
					</div>
				
			  
			  `,
        answer: ["Can we", "Is she", "Are you"],
      },
    ],
  },
  // 5: {
  //   unit: "Review 2",
  //   id: "4WB-R2-P49-E5",
  //   audio: "",
  //   video: "",
  //   component: T6,
  //   exerciseKey: "img/FriendsPlus/Page49/E5/Key/E5answerKey.png",
  //   titleQuestion: [
  //     {
  //       num: "5",
  //       title: `
  // 			Circle the word with a different sound. Write the word that is different.

  // 		  `,
  //       color: "rgb(240,93,36)",
  //     },
  //   ],
  //   titleImage: "",
  //   questionImage: [],
  //   inputSize: 50,
  //   maxLength: 5,
  //   stylesTextInput: {
  //     background: "none",
  //     // borderBottom: "none",s
  //     fontSize: 30,
  //     height: 44,
  //     // textTransform: 'uppercase',
  //     width: 200,
  //   },
  //   textAlign: "center",
  //   isHiddenCheck: true,
  //   questions: [
  //     {
  //       title: `
  // 				<div style=" position: relative ">
  //           <img src='img/FriendsPlus/Page49/E5/1.jpg' width='95%'>

  //           <div style=" position: absolute; margin-top: -260px; left: 650px; width: 200px; border-bottom: 2px solid black "></div>
  //           <div style=" position: absolute; margin-top: -186px; left: 650px; "><input id='1'></input></div>
  //           <div style=" position: absolute; margin-top: -50px; left: 650px; "><input id='2'></input></div>
  // 				</div>

  // 		  `,
  //       answer: ["van", "yo-yo"],
  //     },
  //   ],
  // },
  5: {
    unit: "Review 2",
    id: "4WB-R2-P49-E5",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page49/E5/Key/E5answerKey.PNG",
    component: Circle_Write3,
    // checkUppercase: true,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "5",
        title: `
      			Circle the word with a different sound. Write the word that is different.
      		  `,
        color: "rgb(240,93,36)",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 20,
        inputStyle: {
          width: 200,
          fontSize: 24,
          textAlign: "center",
          height: 29,
          paddingBottom: 10,
        },
        answers: ["van", "yo-yo"],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 28,
          borderRadius: "50%",
          border: "2px solid white",
          fontSize: 25,

          borderColor: "transparent",
          fontWeight: "bold",
          color: "transparent",
        },
        selectWordStyle: {
          border: "2px solid white",
          padding: "38px 28px",
          borderColor: "#00aeef",
          color: "transparent",
        },
        limitSelect: 1,
        listWords: [
          `I1111 &ensp;&ensp;&ensp;&ensp;&ensp; they1 &ensp;&ensp;&ensp;&ensp;&ensp; they1`, //0
          `are22 &ensp;&ensp;&ensp;&ensp;&ensp; 22222 &ensp;&ensp;&ensp;&ensp;&ensp; they2`, //1
        ],
        answers: ["0-0", "1-4"],
        initialValue: [],
      },
      Layout: `
      <div style='position: relative'>
      <img src='img/FriendsPlus/Page49/E5/1.jpg' style='width: 22cm; height: 11cm'>
        
        <div style=" position: absolute; top: 211px; left: 41px"><input id='0' type='Circle'/></div>
        <div style=" position: absolute; top: 341px; left: 41px"><input id='1' type='Circle'/></div>

        <div style=" position: absolute; margin-top: -186px; left: 650px; "><input id='0'></input></div>
        <div style=" position: absolute; margin-top: -50px; left: 650px; "><input id='1'></input></div>

        
	    </div>
      `,
    },
  },
  6: {
    // Exercise num
    unit: "Review 2",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    inputSize: 80,
    hideBtnFooter: true,
    // textAlign: "center",
    // titleImage: "img/FriendsPlus/Page6/E4/title.jpg",

    stylesTextInput: {
      borderBottom: "dash",
    },
    checkUppercase: true,
    questionImage: [],
    mywork: [
      {
        text: "My favorite story in Units 4-6 is",
      },
      { text: "My favorite song in Units 4-6 is" },
      { text: "My favorite unit in Units 4-6 is" },
      { text: "I need to practice" },
      0,
    ],
    myImage: [{ url: "img/FriendsPlus/Page49/E6/1.jpg" }],
    colorBG: "#f25822",
    wordColor: "#fef5d6",
    boxColor: "red",
    questions: [
      {
        title: `
      <div style=' position: relative; '>
				<div><mywork></mywork></div>
          <div style=' position: absolute; top: 45px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 83px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 121px; left:340px; '><input id='0' width='530px'></input></div>
          <div style=' position: absolute; top: 159px; left:210px; '><input id='0' width='660px'></input></div>
      </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
