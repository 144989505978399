import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  7: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P92-E7",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page92/Key/E7answerKey.png",
    inputSize: 350,
    maxLength: 40,
    checkUppercase: true,
    textAlign: "left",
    stylesTextInput: {
      // background: 'none',
      fontSize: 22,
      height: 25,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 7</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=' margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 0px 5px 0px; width: max-content; font-size:22px'>
            <div style='border-bottom:1px solid white; padding-left:20px; padding-right: 10px'>What’s the weather like?&ensp; <b>It's</b> sunny.</div>
            <div style='border-bottom:1px solid white; padding-left:20px; padding-right: 10px'>Put on your scarf. <b>Why? Because </b>it’s cold.</div>
            <div style='padding-left:20px'>Let’s fly a kite <b>because </b>it’s windy.</div>
          </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
           <div style='margin:; line-height: 60px'>
              <div><b>1. </b>Wear a sun hat. Why?&ensp; <u style='color: gray'>Because it’s sunny.</u></div>
              <div><b>2. </b>Take your umbrella. Why? <input id='0' width='500px'> </div>
              <div><b>3. </b>Let’s make a snowman. Why? <input id='1' width='460px'></div>
              <div><b>4. </b>Drink a lot of water. Why? <input id='2' width='500px'> </div>
           </div>
      `,
        answer: [
          `Because it's rainy.`,
          `Because it's snowy.`,
          `Because it's hot ./Because it's sunny.`,
        ],
      },
    ],
  },
  8: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P92-E8",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page92/Key/E8answerKey.png",
    inputSize: 300,
    maxLength: 40,
    checkUppercase: true,
    isHiddenCheck: true,
    textAlign: "center",
    stylesTextInput: {
      // background: 'none',
      fontSize: 23,
      height: 25,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 8</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=' margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 0px 0px 5px 0px; width: max-content; font-size:22px'>
            <div style='display: flex; border-bottom:2px solid white; padding-left:20px; padding-right: 10px'>
              <div style='margin-right: 30px; padding-right:10px; border-right: 2px solid white'>
                He <b>likes</b> balloons.<br>
                She <b>doesn’t like </b>chocolate.
              </div>
              <div>
                They <b>like</b> candy.<br>
                They <b>don’t like </b> apples.
              </div>
            </div>
            <div style=' padding-left:20px'>He likes the presents <b>because</b> they’re new toys.</div>
            
          </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
           <div style='margin:; line-height: 60px'>
              <div><b>1. </b>He <img style="width:0.7cm" src='img/FriendsPlus/Page92/E8/1.jpg' /> <u style='color: gray'>likes </u>the cake because <u style='color: gray'>it’s delicious. </u></div>
              <div><b>2. </b>They <img style="width:0.7cm" src='img/FriendsPlus/Page92/E8/2.jpg' /> # the balloon because # .</div>
              <div><b>3. </b>We <img style="width:0.7cm" src='img/FriendsPlus/Page92/E8/1.jpg' /> # the party because <input id='5' width='400px'> .</div>
              <div><b>4. </b>She <img style="width:0.7cm" src='img/FriendsPlus/Page92/E8/2.jpg' /> # the cake because <input id='5' width='500px'> .</div>
           </div>
      `,
        answer: [
          `don't like`,
          `it pops/goes pop`,
          `like`,
          `it's fun/the children are happy`,
          `doesn't like`,
          "it has jelly/it is chocolate/there are candles",
        ],
      },
    ],
  },
  9: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P92-E9",
    audio: "",
    video: "",
    component: T6,
    // hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page92/Key/E9answerKey.png",
    inputSize: 500,
    maxLength: 80,
    checkUppercase: true,
    textAlign: "left",
    stylesTextInput: {
      // background: 'none',
      fontSize: 22,
      height: 25,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 9</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
          <div style=' margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 5px 20px 5px 20px; width: max-content; font-size:22px'>
            <b>What time is it? It’s </b>six <b>o’clock.</b><br>
            <b>What time </b> do you get up? I get up <b>at </b>six-thirty in the morning.<br>
            <b>What time </b>does he have lunch? He <b>has </b>lunch <b>at </b>twelve-fifteen.
          </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
           <div style='margin:; line-height: 60px'>
              <div><b>1. </b>What time do you get up?&ensp; <u style='color: gray'>I get up at six-fifteen in the morning.</u></div>
              <div><b>2. </b>What time do you have breakfast? # </div>
              <div><b>3. </b>What time do you go to bed? # </div>
              <div><b>4. </b>What time is it now? # </div>
           </div>
      `,
        answer: [
          // `I have breakfast at seven o'clock in the morning.`,
          // `I go to bed at nine-thirty in the evening.`,
          `I have breakfast at (seven o'clock) in the morning.`,
          `I go to bed at (nine-thirty) in the evening.`,
          ``,
        ],
      },
    ],
  },
};

export default json;
