import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P65-E1",
    audio: "Audios/Track 34.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page65/E1/Key/E1answerKey.png",
    inputSize: 40,
    textAlign: "center",
    maxLength: 4,
    stylesTextInput: {
      background: "none",
      fontSize: 23,
      height: 30,
      width: 250,
      borderBottom: "none",
    },
    titleQuestion: [
      {
        num: "1",
        title:
          "Order the letters. Listen and check. <headphone name='34' src='Audios/Track 34.mp3'></headphone>",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style='position:relative'>
        <img style='width:22cm' src="img/FriendsPlus/Page65/E1/1.jpg"/>
        <div style="position: absolute; top:139px; left: 176px; "><input id='0' ></input></div>
        <div style="position: absolute; top:139px; left: 384px; "><input id='1' ></input></div>
        <div style="position: absolute; top:139px; left: 594px; "><input id='2' ></input></div>
        </div>
            
          `,
        answer: ["you", "foot", "new"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P65-E2",
    audio: "Audios/Track 35.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page65/E2/Key/E2answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 150,
    textAlign: "center",
    maxLength: 8,
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 23,
      height: 30,
      // textTransform: 'uppercase',
    },
    titleQuestion: [
      {
        num: "2",
        title:
          "Write. Listen and chant. <headphone name='35' src='Audios/Track 35.mp3'></headphone>",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: ["balloons", "books", "<s>June</s>", "new", "you"],
        width: 600,
        borderColor: "rgb(238,61,60)",
      },
    ],
    questions: [
      {
        title: `
				<hintbox id='0' style='border-radius:2px'></hintbox>
       <div style='position:relative'>
			 
        <div style='display:flex; margin-top:20px; line-height:40px; width:100%' >
				<img style='width:8cm; height: 8cm' src="img/FriendsPlus/Page65/E2/1.jpg" />
          <div>
              <div >Look! It’s<u style='color:gray; text-align:center' ><sup>1</sup> June </u> and</div>
							<div>It’s my birthday!</div>
              <div style='display:flex'>I have a few <div><sup>2</sup> # </div>and</div>
              <div style='display:flex'>A <div><sup>3</sup> # </div>flute.</div>
              <div style='display:flex'>Would <div><sup>4</sup> # </div>like to come</div>
              <div>To my party?</div>
              <div style='display:flex'>There’s cake, <div><sup>3</sup> # </div>,</div>
              <div>And a lot of fruit!</div>
          </div>
          
        </div>
     
      </div>
            
          `,
        answer: ["books", "new", "you", "balloons"],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P65-E3",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page65/E3/Key/E3answerKey.png",
    inputSize: 200,
    textAlign: "center",
    maxLength: 50,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: " Write the words in the correct boxes.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "<s style='color:gray'>book</s>",
          "cube",
          "few",
          "look",
          "new",
          "tube",
          "soup",
          "you",
        ],
        width: 750,
        borderColor: "rgb(238,61,60)",
      },
    ],
    questions: [
      {
        title: `
        <div style='position:relative'></div>
        <hintbox id='0' ></hintbox>
        <div style='display:flex; position: absolute; top: 198px; width: -webkit-fill-available'>
          <div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(252,209,192); border:none; color:rgb(233,59,60); padding:6px; position:absolute; top: 11px; left:12px'>u_e</div>
          <div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(252,209,192); border:none; color:rgb(233,59,60); padding:6px; position:absolute; top: 11px; left:262px'>ew</div>
          <div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(252,209,192); border:none; color:rgb(233,59,60); padding:6px; position:absolute; top: 11px; left:517px'>ou</div>
					<div style='font-size:22px; border-radius:5px; margin:18px; margin-left:90px; text-align:center ;background-color:rgb(252,209,192); border:none; color:rgb(233,59,60); padding:6px; position:absolute; top: 11px; left:765px'>oo</div>
        </div> 
            
         

        <div style='display:flex;margin-top:40px; line-height:50px; text-align:center' >
        <div style='width:25%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
							<div> # </div>
							<div> # </div>
          </div>
          <div style='width:25%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>  
              <div> # </div>
          </div>
          
          
					<div style='width:25%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div> # </div>
              <div> # </div>
          </div>
					<div style='width:25%; border: 2px solid rgb(202, 202, 202); border-radius:30px; padding-top:40px; margin:20px; padding-bottom:20px'>
              <div><u style='color: gray'>&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;book&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;</u> </div>
              <div> # </div>
          </div>
        </div>
            
          `,
        answer: [
          "cube/tube",
          "cube/tube",
          "few/new",
          "few/new",
          "soup/you",
          "soup/you",
          "look",
        ],
      },
    ],
  },
};

export default json;
