import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Unit 11",
    id: "4WB-U11-P78-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page78/E1/Key/E1answerKey.png",
    inputSize: 250,
    textAlign: "center",
    maxLength: 15,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: '<div style="font-size:23px">Write and match.</div>',
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 30,
      width: 150,
    },
    hintBox: [
      {
        src: ["<s>bus</s>", "station", "supermarket"],
        width: 550,
        borderColor: "#234090",
        bgColor: "none",
      },
    ],
    questions: [
      {
        title: `
        <div style='margin-bottom: 30px'><hintbox id='0'></hintbox></div>
				<div style='width: max-content; position:relative; display: flex'>
					<div style='margin-right: 40px'>
							 <div><b>1. </b><u style='color:gray; width:100px'>bus</u> station</div>
							 <div><b>2. </b>train #</div>
							 <div><b>3. </b> # </div>
					</div>
			
			 		
					<div style='position: relative'>
						<img style="width:18cm;" src='img/FriendsPlus/Page78/E1/1.jpg' />
						<div style="position: absolute; top:167px; left:170px "><input id='2' width='60px' border-bottom='none'></input></div>
						<div style="position: absolute; top:167px; left: 385px"><input id='3' width='60px' border-bottom='none'></input></div>
					</div>
					
				</div>
			
            
          `,
        answer: [`station`, `supermarket`, `2`, `3`],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 11",
    id: "4WB-U11-P78-E2",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page78/E2/Key/E2answerKey.png",
    textAlign: "left",
    maxLength: 100,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title: '<div style="font-size:23px">Write.</div>',
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 30,
      width: 800,
    },
    questions: [
      {
        title: `
        <div style='line-height: 50px'>
          <div><b>1. </b>bus station / next to / supermarket</div>
          <div><u>The bus station is next to the supermarket.</u></div>
          <div><b>2. </b>train station / near / bus station</div>
          <div>#</div>
          <div><b>3. </b>supermarket / between / train station / bus station</div>
          <div>#</div>
          </div>
          `,
        answer: [
          `The train station is near the bus station.`,
          `The supermarket is between the train station and the bus station.`,
        ],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 11",
    id: "4WB-U11-P78-E3",
    audio: "Audios/Track 39.mp3",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page78/E3/Key/E3answerKey.png",
    inputSize: 100,
    textAlign: "center",
    maxLength: 20,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title:
          'Listen and write. <headphone name="39" src="Audios/Track 39.mp3"></headphone>',
        color: "#234090",
      },
    ],

    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 30,
      width: 200,
    },
    questions: [
      {
        title: `
       <div style='position:relative'>
       <img style='width:21cm' src='img/FriendsPlus/Page78/E3/1.jpg' />

        <div style=" position: absolute; top: 278px; left: 172px; ">#</div>
        <div style=" position: absolute; top: 321px; left: 22px; ">#</div>
        <div style=" position: absolute; top: 232px; left: 386px; ">#</div>

        <div style=" position: absolute; top: 324px; left: 375px; ">#</div>
        <div style=" position: absolute; top: 370px; left: 513px; ">#</div>
       
     
      </div>
            
          `,
        answer: ["near", "opposite", "left", "next to", "here"],
      },
    ],
  },
};

export default json;
