import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

const HintBox = ({ src, borderColor, width, position, arrow, bgColor }) => {
  const renderPosition = (position) => {
    switch (position) {
      case 1:
        return { left: 53, transform: "skew(-45deg)" };
      case 2:
        return { left: 39, transform: "skew(45deg)" };
      default:
        break;
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          backgroundColor: "white",
          border: `2px solid ${borderColor}`,
          borderRadius: 20,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          paddingBlock: 7,
          paddingInline: 20,
          width,
        }}
      >
        {src.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                color: "black",
                background: `${bgColor}`,
                borderRadius: "15px",
                padding: "3px 12px",
                marginRight: `${index === src.length - 1 ? 0 : 20}px`,
              }}
            >
              {ReactHtmlParser(item)}
            </div>
          );
        })}
      </div>
      {arrow && (
        <div style={{ position: "relative", top: -62 }}>
          <div
            style={{
              borderWidth: "30px 15px 14px",
              borderStyle: "solid",
              borderColor: `${borderColor} transparent transparent`,
              left: 46,
              position: "absolute",
              top: 60,
              transform: renderPosition(position).transform,
            }}
          />
          <div
            style={{
              borderWidth: "30px 15px 14px",
              borderStyle: "solid",
              borderColor: "white transparent transparent",
              left: renderPosition(position).left,
              position: "absolute",
              top: 52,
              transform: renderPosition(position).transform,
            }}
          />
        </div>
      )}
    </div>
  );
};

HintBox.propTypes = {
  src: PropTypes.array.isRequired,
  borderColor: PropTypes.string,
  bgColor: PropTypes.string,
  width: PropTypes.number,
};

HintBox.defaultProps = {
  borderColor: "black",
};

export default HintBox;
