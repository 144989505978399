// import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
// import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
// import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  4: {
    // Exercise num
    unit: "Review 4",
    id: "4WB-R4-P89-E4",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page89/E4/Key/E4answerKey.png",
    inputSize: 150,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 18,
    stylesTextInput: {
      background: "none",
      // borderBottom: 'none',
      fontSize: 22,
      height: 26,
    },
    titleQuestion: [
      {
        num: "4",
        title: "Write the verb.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <img style="width:22cm" src='img/FriendsPlus/Page89/E4/1.jpg' />
        <div style='margin-top:30px; line-height:40px; font-size:22px; display: flex; width: max-content' >
          <div style='margin-right: 40px'>
              <div><b>1.&ensp; </b>He likes <u style='color:gray; text-align:center' >fishing</u>.</div>
              <div><b>2.&ensp; </b>He likes # family.</div>
              <div><b>3.&ensp; </b>She likes  # basketball.</div>
          </div>
          <div style=''>
              <div><b>4.&ensp; </b>She likes # photos.</div>
              <div><b>5.&ensp; </b>She likes # comics.</div>
              <div><b>6.&ensp; </b>They like # volleyball.</div>
          </div>
        </div>
            
          `,
        answer: ["visiting", "playing", "taking", "reading", "playing"],
      },
    ],
  },
  5: {
    unit: "Review 4",
    id: "4WB-R4-P89-E5",
    audio: "",
    video: "",
    recorder: true,
    exerciseKey: "img/FriendsPlus/Page89/E5/Key/E5answerKey.PNG",
    component: Circle_Write,
    checkUppercase: true,
    recorder: true,
    titleQuestion: [
      {
        num: "5",
        title: "Say the word and circle the correct spelling.",
        color: "#23408f",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 30,
        inputStyle: {
          width: 280,
          fontSize: 23,
          textAlign: "center",
          height: 27,
        },
        answers: [``],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px 5px",
          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "5px 5px",

          border: "2px solid black",
          // borderColor: '#00aeef',
        },
        limitSelect: 1,
        listWords: [
          `ir &ensp;&ensp;&ensp; er`, //0
          `er &ensp;&ensp;&ensp; ur`, //1
          `ou &ensp;&ensp;&ensp; oo`, //2
        ],
        answers: ["0-0", "1-4", "2-4"],
        initialValue: [],
      },
      Layout: `
      <div style=' margin-top: 20px; line-height: 50px; position: relative; padding-right: 30px; width: max-content'>
      <img style="width:22cm; " src='img/FriendsPlus/Page89/E5/1.jpg' />
				<div style='position: absolute; top: 253px; left: 289px'><input id='0' type='Circle'/></div>
        <div style='position: absolute; top: 64px; left: 723px'><input id='1' type='Circle'/></div>
				<div style='position: absolute; top: 253px; left: 723px'><input id='2' type='Circle'/></div>

        
	    </div>
      `,
    },
  },
  6: {
    // Exercise num
    unit: "Review 4",
    id: "4WB-R4-P89-E6",
    audio: "",
    video: "",
    component: T6,
    isHiddenCheck: true,
    inputSize: 80,
    hideBtnFooter: true,
    // textAlign: "center",
    // titleImage: "img/FriendsPlus/Page6/E4/title.jpg",
    maxLength: 80,
    stylesTextInput: {
      borderBottom: "dash",
    },
    checkUppercase: true,
    questionImage: [],
    mywork: [
      {
        text: "My favorite story in Units 10-12 is",
      },
      { text: "My favorite song in Units 10-12 is" },
      { text: "My favorite unit in Units 10-12 is" },
      { text: "I need to practice" },
      0,
    ],
    myImage: [{ url: "img/FriendsPlus/Page89/E6/1.jpg" }],
    colorBG: "rgb(35,64,144)",
    wordColor: "rgb(131,202,238)",
    boxColor: "rgb(203,210,230)",
    questions: [
      {
        title: `
      <div style=' position: relative; '>
				<div><mywork></mywork></div>
          <div style=' position: absolute; top: 45px; left:350px; '><input id='0' width='520px'></input></div>
          <div style=' position: absolute; top: 83px; left:350px; '><input id='1' width='520px'></input></div>
          <div style=' position: absolute; top: 121px; left:340px; '><input id='2' width='530px'></input></div>
          <div style=' position: absolute; top: 159px; left:210px; '><input id='3' width='660px'></input></div>
                
      </div>
        `,
        answer: ["", "", "", ""],
      },
    ],
  },
};

export default json;
