import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "4WB-U3-P27-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page27/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 380,
    textAlign: "left",
    maxLength: 30,

    titleQuestion: [
      {
        num: "1",
        title: "Write . or ? at the end of each sentence.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
       <div style='position:relative'>

        <div style='display:flex; margin-top:20px; line-height:50px; width:100%; font-size:25px' >
          <div>
              <div ><b>1.</b>&ensp; Does he like pizza<u style='color:gray; padding-left:199px; text-align:center' >Does he like pizza?</div>
              <div style='display:flex'><b>2.</b>&ensp;I would like an apple<div style='padding-left:180px'>#</div></div>
              <div style='display:flex'><b>3.</b>&ensp; Would you like a sandwich<div style='padding-left:108px'>#</div></div>
              <div style='display:flex'><b>4.</b>&ensp; No, thanks<div style='padding-left:277px'>#</div></div>
          </div>
        </div>
     
      </div>
            
          `,
        answer: [
          "I would like an apple.",
          "Would you like a sandwich?",
          "No, thanks.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 3",
    id: "4WB-U3-P27-E2",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page43/key/E2answerKey.PNG',
    component: Circle_Write,
    titleQuestion: [
      {
        num: "2",
        title: `
				Think of a name for your restaurant. Circle six types of food and drink for the menu. 
					`,
        color: "#23408f",
      },
    ],
    hideBtnFooter: true,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 100,
          textAlign: "center",
          fontSize: 20,
          color: "black",
          maxLength: 5,
        },
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "4px 4px",

          borderRadius: "50%",
          fontWeight: "bold",
          borderColor: "transparent",
          border: "2px solid white",
        },
        selectWordStyle: {
          padding: "4px 4px",

          border: "2px solid rgb(117,186,117)",
          // borderColor: '#00aeef',
        },
        limitSelect: 6,
        listWords: [
          `banana_rice chicken noodles pizza rice <br><br>
					apple_juice bubble_tea mango_juice orange_juice 
          `, // 0
        ],
        answers: [``],
        initialValue: {},
      },
      Layout: `
	  <div style="border: 2px solid rgb(117,186,117); border-radius: 10px">
		<div
			style="
			background-color: rgb(28, 190, 215);
			border-radius: 40px;
			margin: 10px 10px;
			"
		>
		</div> 
	  	<div style='width: 650px ; margin: 10px 10px;'>
			 <input id='0' type='Circle' style="letter-spacing: 10px;"/> 
		  </div>  
    </div>
			
				`,
    },
  },
  3: {
    // Exercise num
    unit: "Unit 3",
    id: "4WB-U3-P27-E3",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page87/E3/Key/E3answerKey.png',
    hideBtnFooter: true,
    inputSize: 150,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 20,
    component: T6,
    // totalInput: 2,
    titleQuestion: [
      {
        num: "3",
        title: "Draw and write a menu.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 21,
      height: 22,
      textAlign: "center",
      // textTransform: 'uppercase',
    },
    questionImage: [],
    questions: [
      {
        title: `
        <img  style='width: 22cm' src='img/FriendsPlus/Page27/E3/1.jpg' />
        <div style='position: relative; font-size:21px'>
        <div style='position:absolute; top:-267px ;left:275px'><input id='0'></div>

        <div style='position:absolute; top: -226px ;left:75px'><input id='1'></div>
        <div style='position:absolute; top: -226px ;left:200px'><input id='2'></div>
        <div style='position:absolute; top: -226px ;left:405px'><input id='3'></div>
        <div style='position:absolute; top: -226px ;left:510px'><input id='4'></div>

        <div style='position:absolute; top: -186px ;left:90px'><input id='5'></div>
        <div style='position:absolute; top: -186px ;left:205px'><input id='6'></div>
        <div style='position:absolute; top: -186px ;left:405px'><input id='7'></div>
        <div style='position:absolute; top: -186px ;left:515px'><input id='8'></div>

        <div style='position:absolute; top: -146px ;left:95px'><input id='9'></div>
        <div style='position:absolute; top: -146px ;left:205px'><input id='10'></div>
        <div style='position:absolute; top: -146px ;left:405px'><input id='11'></div>
        <div style='position:absolute; top: -146px ;left:515px'><input id='12'></div>
        
        </div> 
        `,
        answer: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 3",
    id: "4WB-U3-P27-E4",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page87/E3/Key/E3answerKey.png',
    hideBtnFooter: true,
    inputSize: 125,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 20,
    component: T6,
    // totalInput: 2,
    titleQuestion: [
      {
        num: "4",
        title:
          "Exchange menus with your friend. Talk about the food and drink you would like for lunch. Write.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 21,
      height: 22,
      // textTransform: 'uppercase',
    },
    questionImage: [],
    questions: [
      {
        title: `
        <img  style='width: 22cm' src='img/FriendsPlus/Page27/E4/1.jpg' />
        <div style='position: relative; font-size:21px'>

        <div style='position:absolute; top: -160px ;left:217px'><input id='0'></div>
        <div style='position:absolute; top: -160px ;left:398px'><input id='1'></div>
        <div style='position:absolute; top: -111px ;left:303px'><input id='2'></div>
        <div style='position:absolute; top: -111px ;left:486px'><input id='3'></div>
        
        </div> 
        `,
        answer: ["", "", "", ""],
      },
    ],
  },
};

export default json;
