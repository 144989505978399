import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 7',
		id: '4WB-U7-P54-E1',
		component: T6,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page53/E3/Key/E3answerKey.png',
		inputSize: 10,
		textAlign: 'center',
		maxLength: 8,
		titleQuestion: [
			{
				num: '1',
				title: 'Read.',
				color: '#234090',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
        <img style='width:23cm; height:10cm' src="img/FriendsPlus/Page54/E1/1.jpg" />
          `,
				answer: [],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 7',
		id: '4WB-U7-P54-E2',
		// audio: 'Audios/Track 26.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page54/E2/Key/E2answerKey.png',

		component: DesignUnderLine,
		totalInput: 2,
		titleQuestion: [
			{
				num: '2',
				title: 'Read again. Fill in the correct circle.',
				color: '#234090',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			[{ url: 'img/FriendsPlus/Page54/E2/21.jpg' }],
			// Row 1
			[{ url: 'img/FriendsPlus/Page54/E2/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page54/E2/2.jpg' },
				{ url: 'img/FriendsPlus/Page54/E2/3.jpg' },
				{ url: 'img/FriendsPlus/Page54/E2/4.jpg' },
				{ url: 'img/FriendsPlus/Page54/E2/5.jpg' },
			],
			//Row 2
			[{ url: 'img/FriendsPlus/Page54/E2/6.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page54/E2/7.jpg' },
				{ url: 'img/FriendsPlus/Page54/E2/8.jpg', input: '1', isCorrect: true },
				{ url: 'img/FriendsPlus/Page54/E2/9.jpg' },
				{ url: 'img/FriendsPlus/Page54/E2/10.jpg', input: '1' },
				{ url: 'img/FriendsPlus/Page54/E2/11.jpg' },
				{ url: 'img/FriendsPlus/Page54/E2/12.jpg', input: '1' },
			],
			//Row 3
			[{ url: 'img/FriendsPlus/Page54/E2/13.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page54/E2/14.jpg' },
				{
					url: 'img/FriendsPlus/Page54/E2/15.jpg',
					input: '2',
				},
				{ url: 'img/FriendsPlus/Page54/E2/16.jpg' },
				{ url: 'img/FriendsPlus/Page54/E2/17.jpg', input: '2' },
				{ url: 'img/FriendsPlus/Page54/E2/18.jpg' },
				{
					url: 'img/FriendsPlus/Page54/E2/19.jpg',
					input: '2',
					isCorrect: true,
				},
			],
			[{ url: 'img/FriendsPlus/Page54/E2/20.jpg' }],
		],
	},
	3: {
		// Exercise num
		unit: 'Unit 7',
		id: '4WB-U7-P54-E3',
		// audio: 'Audios/Track 26.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page54/E3/Key/E3answerKey.png',
		isHiddenCheck: true,
		component: DesignUnderLine,
		totalInput: 5,
		titleQuestion: [
			{
				num: '3',
				title: 'Read again and check (✓).',
				color: '#234090',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			[{ url: 'img/FriendsPlus/Page54/E3/22.jpg' }],
			// Row 1
			[{ url: 'img/FriendsPlus/Page54/E3/1.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page54/E3/2.jpg' },
				{ url: 'img/FriendsPlus/Page54/E3/3.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page54/E3/4.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page54/E3/5.jpg', input: 1, isCorrect: true },
			],
			[
				{ url: 'img/FriendsPlus/Page54/E3/6.jpg' },
				{ url: 'img/FriendsPlus/Page54/E3/7.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page54/E3/8.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page54/E3/9.jpg', input: 2, isCorrect: true },
			],
			[
				{ url: 'img/FriendsPlus/Page54/E3/10.jpg' },
				{ url: 'img/FriendsPlus/Page54/E3/11.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page54/E3/12.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page54/E3/13.jpg', input: 3 },
			],
			[
				{ url: 'img/FriendsPlus/Page54/E3/14.jpg' },
				{ url: 'img/FriendsPlus/Page54/E3/15.jpg', input: 4 },
				{ url: 'img/FriendsPlus/Page54/E3/16.jpg', input: 4, isCorrect: true },
				{ url: 'img/FriendsPlus/Page54/E3/17.jpg', input: 4 },
			],
			[
				{ url: 'img/FriendsPlus/Page54/E3/18.jpg' },
				{ url: 'img/FriendsPlus/Page54/E3/19.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page54/E3/20.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page54/E3/21.jpg', input: 5, isCorrect: true },
			],
		],
	},
}

export default json
