import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
const json = {
	1: {
		// Exercise num
		unit: 'Unit 3',
		id: '4WB-U3-P22-E1',
		audio: 'Audios/Track 11.mp3',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page22/E1/Key/E1answerKey.png',

		component: DesignUnderLine,
		totalInput: 3,
		titleQuestion: [
			{
				num: '1',
				title: `Listen. Look at the pictures. Fill in the correct circle.<headphone name='11' src='Audios/Track 11.mp3'></headphone>`,
				color: '#234090',
			},
		],
		// isAllowSubmit: false,
		questionImage: [
			// Row
			[
				{ url: 'img/FriendsPlus/Page22/E1/1.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/2.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page22/E1/3.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/4.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page22/E1/5.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/6.jpg', input: 1, isCorrect: true },
				{ url: 'img/FriendsPlus/Page22/E1/7.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/8.jpg', input: 1 },
				{ url: 'img/FriendsPlus/Page22/E1/9.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page22/E1/10.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/11.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page22/E1/12.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/13.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page22/E1/14.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/15.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page22/E1/16.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/17.jpg', input: 2, isCorrect: true },
				{ url: 'img/FriendsPlus/Page22/E1/18.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/19.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page22/E1/20.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/21.jpg', input: 3, isCorrect: true },
				{ url: 'img/FriendsPlus/Page22/E1/22.jpg' },
				{ url: 'img/FriendsPlus/Page22/E1/23.jpg', input: 3 },
				{ url: 'img/FriendsPlus/Page22/E1/24.jpg' },
			],
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 3',
		id: '4WB-U3-P22-E2',
		audio: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page22/E2/Key/E2answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 40,
		textAlign: 'center',
		maxLength: 1,
		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 20,
			height: 40,
		},
		titleQuestion: [
			{
				num: '2',
				title: 'Look and write.',
				color: '#234090',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `

        <div style='position: relative;margin-top:20px; width:100%; text-align:right' >
          <img style='width:20cm' src="img/FriendsPlus/Page22/E2/1.jpg" />    
          <div style='display:flex; position: absolute; top:109px; left:281px' top:109px; left:281px'>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
              <div>#</div>
          </div>

          <div style='display:flex; position: absolute; top:157px; left:94px'>
            <div style='display:flex; position: relative'>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
            <div style='margin-left:45px; display:flex; position: relative'>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div> 
          </div>
          
          <div style='display:flex; position: absolute; top:303px; left:190px'>
            <div style='display:flex; position: relative'>
              <div>#</div>
            </div>
            <div style='margin-left:45px; display:flex; position: relative'>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div> 
          </div>
          
          <div style='display:flex; position: absolute; top:400px; left:94px'>
            <div style='display:flex; position: relative'>
              <div>#</div>
              <div>#</div>
              <div>#</div>
            </div>
            <div style='margin-left:45px; display:flex; position: relative'>
              <div>#</div>
            </div> 
          </div>

        </div>
            
          `,
				answer: [
					'o',
					'o',
					'd',
					'l',
					'e',
					's',
					'c',
					'h',
					'i',
					'k',
					'e',
					'n',
					'p',
					'z',
					'z',
					'a',
					'f',
					'r',
					'i',
					's',
				],
			},
		],
	},
}

export default json
