import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
// import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
// import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    // Exercise num
    unit: "Unit 8",
    id: "4WB-U8-P57-E1",
    audio: "",
    video: "",
    component: T6,
    maxLength: 45,
    exerciseKey: "img/FriendsPlus/Page57/E1/Key/E1answerKey.png",
    inputSize: 300,
    checkUppercase: true,
    textareaStyle: {
      width: 330,
      height: 75,
      fontSize: 22,
      resize: 'none',
      padding:"5px 10px 5px",
    overflow: 'hidden',
    
     },
    // titleImage: "img/Friends5lus/Page56/E4/1.jpg",
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the pictures and write sentences. Use <i>like</i> and the words.",
        color: "#23408f",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
		<div style=" position: relative ">
		 <img src='img/FriendsPlus/Page57/E1/1.jpg' style='width:22cm'>

		 <div style=" position: absolute; top: 155px; left: 449px; "><textarea  id='0' rows='2'></textarea></div>
		 <div style=" position: absolute; top: 383px; left: 14px; "><textarea id='1' rows='2'></textarea></div>
		 <div style=" position: absolute; top: 383px; left: 449px; "><textarea id='2' rows='2'></textarea></div>
		</div>
        `,
        answer: [
          "They like presents because they're fun.",
          "They don't like balloons because they pop.",
          "They don't like this cake because it has jelly.",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "4WB-U8-P57-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page57/E2/Key/E2answerKey.PNG",
    component: Circle_Write,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title: "Circle and write.",
        color: "#23408f",
      },
    ],
    question: {
      Write: {
        isHiddenCheck: true,
        maxLength: 30,
        inputStyle: {
          width: 280,
          fontSize: 23,
          textAlign: "center",
          height: 27,
        },
        answers: [`No they don't. / No they don't.`, `They like cards. / They like cards`, `do, I like`],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: 0,
          border: "2px solid white",
          borderRadius: "50%",
          borderColor: "transparent",
          fontWeight: "bold",
          // color: '#00aeef',
        },
        selectWordStyle: {
          border: "2px solid white",
          borderColor: "#00aeef",
        },
        limitSelect: 1,
        listWords: [
          `Does / Do`, //0
          `does / do`, //1
          `does / do`, //2
        ],
        answers: ['0-4', '1-4', '2-4'],
        initialValue: [],
      },
      Layout: `
      <div style=' margin-top: 20px; line-height: 50px'>
      <img style="width:17cm;" src='img/FriendsPlus/Page57/E2/1.jpg' />
        <div><b>1. <span style='border: 2px solid gray; border-radius: 50%; padding: 5px'>Does </span>/ Do </b>he like balloons? <u style='color:gray'>No, he doesn’t.</u></div>
        <div><b>2. </b><input id='0' type='Circle'/> they like candy? <input id='0' ></input> </div>
        <div><b>3. </b>What <input id='1' type='Circle'/> they like? <input id='1' ></input></div>
        <div><b>4. </b>What <input id='2' type='Circle'/> you like? <input id='2' ></input> </div>

        
	    </div>
      `,
    },
  },
};

export default json;
