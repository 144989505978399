import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  // 1: {
  //   // Exercise num
  //   unit: "Unit 12",
  //   id: "4WB-U12-P84-E1",
  //   audio: "",
  //   video: "",
  //   component: MatchDots,
  //   exerciseKey: "img/FriendsPlus/Page84/E1/Key/E1answerKey.png",
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: "Find and write the words.",
  //       color: "#2E479B",
  //     },
  //   ],
  //   question: {
  //     DrawLines: {
  //       fontWeight: "120px",
  //       multipleLine: true,
  //       boxMatch: [
  //         //Clock 2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "95px",
  //             left: "400px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "95px",
  //             left: "428px",
  //             width: 4,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "95px",
  //             left: "447px",
  //             width: 4,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "95px",
  //             left: "464px",
  //             width: 4,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "95px",
  //             left: "479px",
  //             width: 4,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "95px",
  //             left: "499px",
  //             width: 4,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "95px",
  //             left: "520px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//7
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "95px",
  //             left: "539px",
  //             width: 4,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //0//8
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "88px",
  //             left: "557px",
  //             width: 4,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "88px",
  //             left: "574px",
  //             width: 4,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//2

  //         //CLOCK 3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "115px",
  //             left: "575px",
  //             width: 4,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "115px",
  //             left: "560px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "115px",
  //             left: "540px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "115px",
  //             left: "520px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "121px",
  //             left: "500px",
  //             width: 5,
  //             height: 4,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//7
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "121px",
  //             left: "480px",
  //             width: 5,
  //             height: 4,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //1//8
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "117px",
  //             left: "463px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "117px",
  //             left: "447px",
  //             width: 4,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "117px",
  //             left: "429px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "117px",
  //             left: "400px",
  //             width: 5,
  //             height: 5,
  //             backgroundColor: "rgb(67,79,161)",
  //             borderRadius: "50px",
  //           },
  //         }, //2//4
  //       ],
  //       answers: ['0-1', '1-2', '2-3', '3-4', '4-5', '5-6', '6-7', '0-7', '8-9', '10-9', '10-11', '11-12', '12-13', '13-14', '14-15', '15-8', '16-17', '17-18', '18-19', '19-20', '20-21', '16-21', '22-23', '23-24', '24-25', '25-26', '26-27', '22-27'],
  //       initialValue: [],
  //     },
  //     Layout: `
  //     <img style='width: 23cm; margin-bottom:50px' src="img/FriendsPlus/Page84/E1/1.jpg" />
  //         <input id='0' type='boxMatch' />
  //         <input id='1' type='boxMatch' />
  //         <input id='2' type='boxMatch' />
  //         <input id='3' type='boxMatch' />
  //         <input id='4' type='boxMatch' />
  //         <input id='5' type='boxMatch' />
  //         <input id='6' type='boxMatch' />
  //         <input id='7' type='boxMatch' />
  //         <input id='8' type='boxMatch' />
  //         <input id='9' type='boxMatch' />
  //         <input id='10' type='boxMatch' />
  //         <input id='11' type='boxMatch' />

  // 				<input id='12' type='boxMatch' />
  // 				<input id='13' type='boxMatch' />
  // 				<input id='14' type='boxMatch' />
  // 				<input id='15' type='boxMatch' />
  // 				<input id='16' type='boxMatch' />
  // 				<input id='17' type='boxMatch' />
  // 				<input id='18' type='boxMatch' />
  // 				<input id='19' type='boxMatch' />
  //     `,
  //   },
  // },
  1: {
    // Exercise num
    unit: "Unit 12",
    id: "4WB-U12-P84-E1",
    audio: "",
    // hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page84/Key/E1answerKey.png",
    component: T6,
    inputSize: 550,
    maxLength: 100,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: "uppercase",
      borderStyle: "2px dotted",
      textAlign: "left",
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Find and write the words.",
        color: "#234090",
      },
    ],
    questions: [
      {
        title: `

            <div> <img style='width:22cm' src='img/FriendsPlus/Page84/E1/1.jpg' /> </div>
  			<div>
  			<i style='font-size:18px; color: rgb(245,54,92)'>*Write the selected words in the sorted order of the list above and between words seperated by commas (,).</i></div>
        <div style='display: flex; margin-top: 30px'>
        <b>Answer:&ensp;</b><i style='color: gray'>floor,</i>#

        </div>
          `,
        answer: ["messy, neat / neat, messy"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 12",
    id: "4WB-U12-P84-E2",
    audio: "Audios/Track 42.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page84/Key/E2answerKey.png",

    component: DesignUnderLine,
    totalInput: 4,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and look at the pictures. Fill in the correct circle. <headphone name='42' src='Audios/Track 42.mp3'></headphone>",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row 1
      [{ url: "img/FriendsPlus/Page84/E2/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page84/E2/2.jpg" },
        { url: "img/FriendsPlus/Page84/E2/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page84/E2/4.jpg" },
        { url: "img/FriendsPlus/Page84/E2/5.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page84/E2/6.jpg" },
        { url: "img/FriendsPlus/Page84/E2/7.jpg", input: 1 },
        { url: "img/FriendsPlus/Page84/E2/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page84/E2/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page84/E2/10.jpg" },
        { url: "img/FriendsPlus/Page84/E2/11.jpg", input: 1 },
        { url: "img/FriendsPlus/Page84/E2/12.jpg" },
        { url: "img/FriendsPlus/Page84/E2/13.jpg", input: 2 },
        { url: "img/FriendsPlus/Page84/E2/14.jpg" },
        { url: "img/FriendsPlus/Page84/E2/15.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page84/E2/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page84/E2/17.jpg" }],
      [
        { url: "img/FriendsPlus/Page84/E2/18.jpg" },
        { url: "img/FriendsPlus/Page84/E2/19.jpg", input: 3 },
        { url: "img/FriendsPlus/Page84/E2/20.jpg" },
        { url: "img/FriendsPlus/Page84/E2/21.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page84/E2/22.jpg" },
        { url: "img/FriendsPlus/Page84/E2/23.jpg", input: 3 },
        { url: "img/FriendsPlus/Page84/E2/24.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page84/E2/25.jpg" }],
      [
        { url: "img/FriendsPlus/Page84/E2/26.jpg" },
        { url: "img/FriendsPlus/Page84/E2/27.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page84/E2/28.jpg" },
        { url: "img/FriendsPlus/Page84/E2/29.jpg", input: 4 },
        { url: "img/FriendsPlus/Page84/E2/30.jpg" },
        { url: "img/FriendsPlus/Page84/E2/31.jpg", input: 4 },
        { url: "img/FriendsPlus/Page84/E2/32.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page84/E2/33.jpg" }],
    ],
  },
};

export default json;
