import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  // 1: {
  //   unit: "Unit 2",
  //   id: "4WB-U2-P16-E1",
  //   exerciseKey: "img/FriendsPlus/Page16/E1/Key/E1answerKey.PNG",
  //   video: "",
  //   // audio: 'Audios/2.12.mp3',
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: `Find and select the words.`,
  //       color: "#234090",
  //     },
  //   ],
  //   totalInput: 33,
  //   isHiddenCheck: true,
  //   component: DesignUnderLine,

  //   questionImage: [
  //     [{ url: "img/FriendsPlus/Page16/E1/1.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page16/E1/2.jpg" },
  //       { url: "img/FriendsPlus/Page16/E1/3.jpg", input: 1 },
  //       { url: "img/FriendsPlus/Page16/E1/4.jpg", input: 2 },
  //       { url: "img/FriendsPlus/Page16/E1/5.jpg", input: 3, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/6.jpg", input: 4, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/7.jpg", input: 5, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/8.jpg", input: 6, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/9.jpg", input: 7, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/10.jpg", input: 8, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/11.jpg", input: 9, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/12.jpg", input: 10, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/13.jpg", input: 11, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/14.jpg", input: 12 },
  //       { url: "img/FriendsPlus/Page16/E1/15.jpg", input: 13 },
  //     ],
  //     [{ url: "img/FriendsPlus/Page16/E1/16.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page16/E1/17.jpg" },
  //       { url: "img/FriendsPlus/Page16/E1/18.jpg", input: 14 },
  //       { url: "img/FriendsPlus/Page16/E1/19.jpg", input: 15 },
  //       { url: "img/FriendsPlus/Page16/E1/20.jpg", input: 16 },
  //       { url: "img/FriendsPlus/Page16/E1/21.jpg", input: 17, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/22.jpg", input: 18, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/23.jpg", input: 19, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/24.jpg", input: 20, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/25.jpg", input: 21, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/26.jpg", input: 22 },
  //       { url: "img/FriendsPlus/Page16/E1/27.jpg", input: 23 },
  //       { url: "img/FriendsPlus/Page16/E1/28.jpg", input: 24 },
  //       { url: "img/FriendsPlus/Page16/E1/29.jpg" },
  //       { url: "img/FriendsPlus/Page16/E1/30.jpg", input: 25 },
  //       { url: "img/FriendsPlus/Page16/E1/31.jpg", input: 26 },
  //       { url: "img/FriendsPlus/Page16/E1/32.jpg", input: 27 },
  //       { url: "img/FriendsPlus/Page16/E1/33.jpg", input: 28, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/34.jpg", input: 29, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/35.jpg", input: 30, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/36.jpg", input: 31, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/37.jpg", input: 32, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/38.jpg", input: 33, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/39.jpg", input: 34, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/40.jpg", input: 35 },
  //       { url: "img/FriendsPlus/Page16/E1/41.jpg", input: 36 },
  //       { url: "img/FriendsPlus/Page16/E1/42.jpg", input: 37 },
  //     ],
  //     [{ url: "img/FriendsPlus/Page16/E1/43.jpg" }],
  //     [
  //       { url: "img/FriendsPlus/Page16/E1/44.jpg" },
  //       { url: "img/FriendsPlus/Page16/E1/45.jpg", input: 38 },
  //       { url: "img/FriendsPlus/Page16/E1/46.jpg", input: 39, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/47.jpg", input: 40, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/48.jpg", input: 41, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/49.jpg", input: 42, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/50.jpg", input: 43, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/51.jpg", input: 44, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/52.jpg", input: 45 },
  //       { url: "img/FriendsPlus/Page16/E1/53.jpg" },
  //       { url: "img/FriendsPlus/Page16/E1/54.jpg", input: 46 },
  //       { url: "img/FriendsPlus/Page16/E1/55.jpg", input: 47, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/56.jpg", input: 48, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/57.jpg", input: 49, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/58.jpg", input: 50, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/59.jpg", input: 51, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/60.jpg", input: 52, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/61.jpg", input: 53, isCorrect: true },
  //       { url: "img/FriendsPlus/Page16/E1/62.jpg", input: 54 },
  //       { url: "img/FriendsPlus/Page16/E1/63.jpg" },
  //     ],
  //     [{ url: "img/FriendsPlus/Page16/E1/64.jpg" }],
  //   ],
  // },
  // 1: {
  //   unit: "Unit 2",
  //   id: "4WB-U2-P16-E1",
  //   exerciseKey: "img/FriendsPlus/Page16/E1/Key/E1answerKey.PNG",
  //   component: Circle_Write,
  //   titleQuestion: [
  //     {
  //       num: "1",
  //       title: `Find and select the words.`,
  //       color: "#234090",
  //     },
  //   ],
  //   // hideBtnFooter: true,
  //   question: {
  //     Write: {
  //       underlineStyle: {},
  //       inputStyle: {
  //         width: 100,
  //         textAlign: "center",
  //         fontSize: 20,
  //         color: "black",
  //         maxLength: 5,
  //       },
  //       answers: [],
  //       initialValue: [],
  //     },
  //     Circle: {
  //       initialWordStyle: {
  //         padding: 2,
  //         // borderColor: "transparent",
  //         fontWeight: 600,
  //         fontSize: 23,
  //         color: "rgb(88,147,198)",
  //       },

  //       selectWordStyle: {
  //         padding: 1.5,
  //         border: "2px solid black",
  //         borderRadius: "50%",
  //         // borderColor: "",
  //       },
  //       limitSelect: 9,
  //       listWords: [
  //         `c h t s t o r e u b x`,
  //         `b p o l i c e z`,
  //         `h s t a t i o n x`,
  //         `a r h o s p i t a l o u`,
  //         `m k l a i r p o r t q r y`,
  //       ],
  //       answers: [
  //         "0-6",
  //         "0-8",
  //         "0-10",
  //         "0-12",
  //         "0-14",
  //         "1-2",
  //         "1-4",
  //         "1-6",
  //         "1-8",
  //         "1-10",
  //         "1-12",
  //         "2-2",
  //         "2-4",
  //         "2-6",
  //         "2-8",
  //         "2-10",
  //         "2-12",
  //         "2-14",
  //         "3-4",
  //         "3-6",
  //         "3-8",
  //         "3-10",
  //         "3-12",
  //         "3-14",
  //         "3-16",
  //         "3-18",
  //         "4-6",
  //         "4-8",
  //         "4-10",
  //         "4-12",
  //         "4-14",
  //         "4-16",
  //         "4-18",
  //       ],
  //       initialValue: {},
  //     },
  //     Layout: `
  // 	  <div style='position: relative; margin-top: 50px'>
  //     <img style='width: 19cm; height: 12cm; margin-right: 350px;' src='img/FriendsPlus/Page16/E3/1.jpg' />
  //     <div style='position: absolute; top: 209px; left: 208px'><input id='0' type='Circle'/></div>
  //     <div style='position: absolute; top: 355px; left: 208px'><input id='1' type='Circle'/></div>
  //     <div style='position: absolute; top: 355px; left: 415px'><input id='2' type='Circle'/></div>
  //     <div style='position: absolute; top: 64px; left: 725px'><input id='3' type='Circle'/></div>
  //     <div style='position: absolute; top: 209px; left: 725px'><input id='4' type='Circle'/></div>
  //     </div>

  // 			`,
  //   },
  // },
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "4WB-U2-P16-E1",
    exerciseKey: "img/FriendsPlus/Page16/E1/Key/E1answerKey.PNG",
    component: T6,
    // inputSize: 167,
    textAlign: "center",
    maxLength: 20,
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 24,
      height: 28,
      width: 200,
    },
    titleQuestion: [
      {
        num: "1",
        title: `Find and select the words.`,
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style=" position: relative; ">
        <img style='width: 28cm; height: 14cm; margin-left: -100px' src='img/FriendsPlus/Page16/E3/2.jpg' /> 
            <div style="position: absolute; top:310px; left: 169px; "><input id='0' ></input></div>
            <div style="position: absolute; top:461px; left: 169px; "><input id='1' width='125px'></input></div>
            <div style="position: absolute; top:461px; left: 314px; "><input id='2' width='145px'></input></div>
            <div style="position: absolute; top:160px; left: 653px; "><input id='3' ></input></div>
            <div style="position: absolute; top:310px; left: 653px; "><input id='4' ></input></div>
						
					</div>
        
          
          `,
        answer: ["store", "police", "station", "hospital", "airport"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 2",
    id: "4WB-U2-P16-E2",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page16/E2/Key/E2answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    component: T6,
    inputSize: 167,
    textAlign: "center",
    maxLength: 30,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 21,
      height: 22,
      width: 180,
    },
    titleQuestion: [
      {
        num: "2",
        title: "Look and write.",
        color: "#234090",
      },
    ],
    isHiddenCheck: true,
    questions: [
      {
        title: `
        <div style=" position: relative; ">
						<div> <img style='width:20cm' src='img/FriendsPlus/Page16/E2/1.jpg' /> </div>
            <div style="width:180px; position: absolute; top:172px; left: 285px; "><input id='0' ></input></div>
            <div style="width:180px; position: absolute; top:172px; left: 558px; "><input id='1' ></input></div>
            <div style="width:180px; position: absolute; top:402px; left: 65px; "><input id='2' ></input></div>
            <div style="width:180px; position: absolute; top:402px; left: 336px; "><input id='3' ></input></div>
						
					</div>
        
          
          `,
        answer: ["airport", "store", "police station", "fire station"],
      },
    ],
  },
};

export default json;
