import UI from '../../components/ExcerciseTypes/Design/UserInterface'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'
import MatchDotsWrite from '../../components/ExcerciseTypes/LineTo/MatchDotsWrite'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 12',
		id: '4WB-U12-P86-E1',
		component: T6,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page53/E3/Key/E3answerKey.png',
		inputSize: 10,
		textAlign: 'center',
		maxLength: 8,
		titleQuestion: [
			{
				num: '1',
				title: 'Read.',
				color: '#234090',
			},
		],
		isHiddenCheck: true,
		questions: [
			{
				title: `
        <img style='width:23cm; height:10cm' src="img/FriendsPlus/Page86/E1/1.jpg" />
          `,
				answer: [],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 12',
		id: '4WB-U12-P86-E2',
		// audio: 'Audios/Track 16.mp3',
		video: '',
		component: MatchDotsWrite,
		exerciseKey: 'img/FriendsPlus/Page86/E2/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: 'Read again. Match the children to the animals.',
				color: '#234090',
			},
		],
		// stylesTextInput: {
		// 		background: 'none',
		// 		borderBottom: 'none',
		// 		fontSize: 23,
		// 		height: 35,
		// 		width: 40,
		// 	},
		inputSize: 50,

		question: {
			Write: {
				underlineStyle: {},
				inputStyle: {
					background: 'none',
					borderBottom: 'none',
					fontSize: 24,
					height: 20,
					width: 40,
					maxLength:1,
				},
				isHiddenCheck:true,

				answers: ['a','c'],
				initialValue: [],
			  },
		  DrawLines: {
			multipleLine: false,
			boxMatch: [
			 
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '445px',
				  left: '178px',
				  width: '40px',
				  height: '40px',
				  border: 'none',
				  background: 'none',
				},
			  }, // 2
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '495px',
				  left: '178px',
				  width: '40px',
				  height: '40px',
				  border: 'none',
				  background: 'none',
				},
			  }, // 3
			  
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '395px',
				  left: '435px',
				  width: '80px',
				  height: '40px',
				  border: 'none',
				  background: 'none',
				},
			  }, // B
			  {
				boxMatchStyle: {
				  position: 'absolute',
				  top: '495px',
				  left: '435px',
				  width: '80px',
				  height: '40px',
				  border: 'none',
				  background: 'none',
				},
			  }, // C
			 
			 
			 
			],
			answers: ['0-2', '1-3'],
			initialValue: [],
		  },
		  Layout: `
	
		  <img style='width:20cm' src="img/FriendsPlus/Page86/E1/1.jpg" />
        <div style='position: relative; width:100%' >
            <img style='width:16cm' src="img/FriendsPlus/Page86/E2/1.jpg" />    
            
            <div style=" position: absolute; top: 63px; left: 138px;">
			<input id='0'/></div>
            <div style=" position: absolute; top: 116px; left: 138px;">
			<input id='1'/></div>
            </div>
			  <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
			 
			
		  `,
		},
	  },
	// 2: {
	// 	// Exercise num
	// 	unit: 'Unit 12',
	// 	id: '4WB-U12-P86-E2',
	// 	// audio: 'Audios/Track 28.mp3',
	// 	component: T6,
	// 	exerciseKey: 'img/FriendsPlus/Page86/E2/Key/E2answerKey.png',
	// 	// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
	// 	// inputSize: 1,
	// 	textAlign: 'center',
	// 	maxLength: 1,
	// 	stylesTextInput: {
	// 		background: 'none',
	// 		borderBottom: 'none',
	// 		fontSize: 24,
	// 		height: 20,
	// 		width: 40,
	// 	},
	// 	titleQuestion: [
	// 		{
	// 			num: '2',
	// 			title: 'Read again. Match the children to the animals.',
	// 			color: '#234090',
	// 		},
	// 	],
	// 	isHiddenCheck: true,
	// 	questions: [
	// 		{
	// 			title: `
    //     <img style='width:20cm' src="img/FriendsPlus/Page86/E1/1.jpg" />
    //     <div style='position: relative; width:100%' >
    //         <img style='width:16cm' src="img/FriendsPlus/Page86/E2/1.jpg" />    
            
    //         <div style=" position: absolute; top: 61px; left: 124px;">#</div>
    //         <div style=" position: absolute; top: 113px; left: 124px;">#</div>
    //         </div>
            
    //       `,
	// 			answer: ['a', 'c'],
	// 		},
	// 	],
	// },
	3: {
		// Exercise num
		unit: 'Unit 12',
		id: '4WB-U12-P86-E3',
		audio: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page86/E3/Key/E3answerKey.png',
		// exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
		inputSize: 250,
		textAlign: 'center',
		maxLength: 20,
		stylesTextInput: {
			background: 'none',
			// borderBottom: 'none',
			fontSize: 22,
			height: 26,
			// textTransform: 'uppercase',
		},
		titleQuestion: [
			{
				num: '3',
				title: 'Read again and answer the questions.',
				color: '#234090',
			},
		],

		questions: [
			{
				title: `
        <img style='width:17cm' src="img/FriendsPlus/Page86/E1/1.jpg" />
        <div style=' line-height:40px; font-size:22px' >
          <div>
              <div><b>1. </b> What class is Thanh in? <u style='color:gray; text-align:center' >4B</u></div>
              <div style='display:flex'><b>2. </b>What was the name of the class play? <div># .</div></div>
              <div style='display:flex'><b>3. </b>What was Thanh?  <div> # </div></div>
              <div style='display:flex'><b>4. </b>What was Long? <div> # </div></div>
              <div style='display:flex'><b>5. </b>What was Mai? <div> # </div></div>
              <div style='display:flex'><b>6. </b>How were the moms and dads? <div> # </div></div>
          </div>
          
        </div>
            
          `,
				answer: [
					'On the farm/on the farm',
					'a horse',
					'a sheep',
					'a hen',
					'happy',
				],
			},
		],
	},
}

export default json
