import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import DrawColor from "../../components/ExcerciseTypes/DrawColor";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  1: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P15-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page15/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 350,
    textAlign: "left",
    maxLength: 100,
    stylesTextInput: {
      background: "none",
      fontSize: 23,
      height: 30,
    },
    titleQuestion: [
      {
        num: "1",
        title: " Write the sentence with a capital letter and a period.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <div style='margin-top:20px; line-height:50px; width:100%' >
          <div ><b>1.</b>&ensp; doctors help sick people <u style='color:gray; padding-left:40px; text-align:center' > Doctors help sick people.</div>
          <div style='display:flex'><b>2.</b>&ensp; they’re farmers<div style='padding-left:142px'>#.</div></div>
          <div style='display:flex'><b>3.</b>&ensp;  firefighters fight fires<div style='padding-left:79px'>#.</div></div>
          <div style='display:flex'><b>4.</b>&ensp; we’re students<div style='padding-left:151px'>#.</div></div>         
        </div>
            
          `,
        answer: [
          "They're farmers",
          "Firefighters fight fires",
          "We're students",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P15-E2",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page87/E3/Key/E3answerKey.png',
    hideBtnFooter: true,
    component: T6,
    // totalInput: 2,
    inputSize: 150,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 25,
    titleQuestion: [
      {
        num: "2",
        title: "Write the names of people in your family and their jobs.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 25,
      // textTransform: 'uppercase',
    },
    questionImage: [],
    questions: [
      {
        title: `
        <img  style='width: 24cm' src='img/FriendsPlus/Page15/E2/1.jpg' />
        <div style='position: relative; font-size:21px'>
					<div style="position: absolute; top: -171px; left: 411px; ">#</div>
					<div style="position: absolute; top: -171px; left: 588px; ">#</div>

          <div style="position: absolute; top: -127px; left: 37px; ">#</div>
					<div style="position: absolute; top: -127px; left: 213px; ">#</div>

          <div style="position: absolute; top: -127px; left: 411px; ">#</div>
					<div style="position: absolute; top: -127px; left: 588px; ">#</div>
          </div> 
        `,
        answer: [""],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P15-E3",
    audio: "",
    video: "",
    // exerciseKey: 'img/FriendsPlus/Page87/E3/Key/E3answerKey.png',
    hideBtnFooter: true,
    component: T6,
    // totalInput: 2,
    textAlign: "center",
    isHiddenCheck: true,
    maxLength: 30,
    titleQuestion: [
      {
        num: "3",
        title: "Stick a photo of you and your family. Write about their jobs.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 22,
      // textTransform: 'uppercase',
    },
    textareaStyle: {
      // background: 'gray',
      width: 385,
      fontSize: 21,
      height: 200,
    },
    questionImage: [],
    questions: [
      {
        title: `
        <img  style='width: 22cm' src='img/FriendsPlus/Page15/E3/1.jpg' />
        <div style='position: relative; font-size:21px'>
					<div style="position: absolute; top: -464px; left: 504px; "><input id='0' width='230px'></div>
          <div style="position: absolute; top: -279px; left: 400px; "><textarea id='2' row='3'></div>
        
        </div> 
        `,
        answer: ["11", "22"],
      },
    ],
  },
  4: {
    // Exercise num
    unit: "Unit 1",
    id: "4WB-U1-P15-E4",
    audio: "",
    video: "",
    exerciseKey: "",
    recorder: true,
    component: UI,
    fontSize: 30,
    maxLength: 1,

    titleQuestion: [
      {
        num: "4",
        title: "Tell your friend about your family and their jobs.",
        color: "#234090",
      },
    ],
    questionImage: [],
  },
};

export default json;
