import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 3",
    id: "4WB-U3-P26-E1",
    audio: "",
    hideBtnFooter: true,
    // exerciseKey: 'img/FriendsPlus/Page7/Key/E1answerKey.png',
    component: T6,
    inputSize: 250,
    textAlign: "center",
    maxLength: 18,

    titleQuestion: [
      {
        num: "1",
        title: "Read.",
        color: "#234090",
      },
    ],
    questions: [
      {
        title: `	
       
            <div> <img style='width:22cm' src='img/FriendsPlus/Page26/E1/1.jpg' /> </div>
          `,
        answer: [""],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 3",
    id: "4WB-U3-P26-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page26/E2/Key/E2answerKey.png",

    component: DesignUnderLine,
    isHiddenCheck: true,
    totalInput: 4,
    titleQuestion: [
      {
        num: "2",
        title: "Read again. Check (✓) what they have.",
        color: "#234090",
      },
    ],
    // isAllowSubmit: false,
    questionImage: [
      // Row 1
      [{ url: "img/FriendsPlus/Page26/E2/1.jpg" }],
      [{ url: "img/FriendsPlus/Page26/E2/2.jpg" }],
      [
        { url: "img/FriendsPlus/Page26/E2/3.jpg" },
        { url: "img/FriendsPlus/Page26/E2/4.jpg", input: 1 },
        { url: "img/FriendsPlus/Page26/E2/5.jpg", input: 1, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page26/E2/6.jpg" },
        { url: "img/FriendsPlus/Page26/E2/7.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page26/E2/8.jpg", input: 2 },
      ],
      [
        { url: "img/FriendsPlus/Page26/E2/9.jpg" },
        { url: "img/FriendsPlus/Page26/E2/10.jpg", input: 3 },
        { url: "img/FriendsPlus/Page26/E2/11.jpg", input: 3, isCorrect: true },
      ],
      [
        { url: "img/FriendsPlus/Page26/E2/12.jpg" },
        { url: "img/FriendsPlus/Page26/E2/13.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page26/E2/14.jpg", input: 4 },
      ],
      [{ url: "img/FriendsPlus/Page26/E2/15.jpg" }],
    ],
  },
  3: {
    unit: "Unit 3",
    id: "SB7-U3-P26-E3",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page26/E3/Key/E3answerKey.png",
    titleQuestion: [
      {
        num: "3",
        title: "Read again. Write <i>Yes</i> or <i>No</i>.",
        color: "#234090",
      },
    ],
    selectStyle: {
      color: "#00ADFE",
      width: 80,
      textAlign: "center",
      fontSize: 20,
      height: 30,
    },
    selectOptionRandom: true,
    selectOptionValues: ["Yes", "No"],
    questionImage: [],
    questions: [
      {
        title: `

        <div style='position: relative; padding-top:30px'>
          <img src='img/FriendsPlus/Page26/E3/1.jpg' />
          
          <div style=" position: absolute; top: 106px; left: 387px; "><i><select id=0></select></i></div>
          <div style=" position: absolute; top: 169px; left: 392px; "><i><select id=1></select></i></div>
          <div style=" position: absolute; top: 42px; left: 815px; "><i><select id=2></select></i></div>
          <div style=" position: absolute; top: 105px; left: 791px; "><i><select id=3></select></i></div>
          <div style=" position: absolute; top: 170px; left: 797px; "><i><select id=4></select></i></div>
          <img style='width:18.5cm; height:9cm' src='img/FriendsPlus/Page26/E1/1.jpg' />
        </div> 
        `,
        answer: ["No", "No", "Yes", "No", "Yes"],
      },
    ],
  },
};

export default json;
