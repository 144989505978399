import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDotsWrite from "../../components/ExcerciseTypes/LineTo/MatchDotsWrite";

const json = {
  1: {
    // Exercise num
    unit: "Unit 2",
    id: "4WB-U2-P17-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page17/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    component: T6,
    inputSize: 178,
    textAlign: "center",
    maxLength: 15,
    textareaStyle: {
      width: 175,
      height: 75,
      fontSize: 22,
      resize: "none",
      padding: "5px 10px 5px",
      overflow: "hidden",
    },
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 22,
    },
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Follow the maze and write.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "<p style='color:gray'>hospital</p>",
          "<p style='color:gray'>school</ơ>",
          "<p style='color:gray'>airport</p>",
          "<s style='color:gray'>police station</s>",
          "<p style='color:gray'>fire station</p>",
          "<p style='color:gray'>store</p>",
        ],
        width: 900,
        borderColor: "rgb(133,208,151)",
        fontSize: 20,
      },
    ],
    questions: [
      {
        title: `
        <hintbox id='0' ></hintbox>
        <div style=" position: relative; ">
					<div> <img style='width:22cm' src='img/FriendsPlus/Page17/E1/1.jpg' /> </div>
            <div style=" position: absolute; top:160px; left: 624px;">#</div>
            <div style="position: absolute; top:230px; left: 635px;"><textarea id='1' rows='2'></textarea></div>
            <div style=" position: absolute; top:340px; left: 635px;"><textarea id='2' rows='2'></textarea></div>
            <div style=" position: absolute; top:450px; left: 635px;"><textarea id='3' rows='2'></textarea></div>
            <div style=" position: absolute; top:560px; left: 635px;"><textarea id='4' rows='2'></textarea></div>
            
				</div>

          `,
        answer: [
          "school",
          "He works in an airport",
          "She works in a fire station",
          "He works in a store",
          "She works in a hospital",
        ],
      },
    ],
  },

  2: {
    // Exercise num
    unit: "Unit 2",
    id: "4WB-U2-P17-E2",
    // audio: 'Audios/Track 08.mp3',
    video: "",
    component: MatchDotsWrite,
    exerciseKey: "img/FriendsPlus/Page17/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: "Match.",
        color: "#234090",
      },
    ],
    inputSize: 50,
    question: {
      Write: {
        underlineStyle: {},
        inputStyle: {
          width: 40,
          // height: 40,
          textAlign: "center",
          fontSize: 24,
          color: "black",
          marginTop: "3px",
          maxLength: 1,
          // backgroundColor: "blue",
        },
        isHiddenCheck: true,

        answers: ["c", "a", "d"],
        initialValue: [],
      },
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          //left
          {
            boxMatchStyle: {
              position: "absolute",
              top: "188px",
              left: "562px",
              width: 16,
              height: 16,
              backgroundColor: "rgb(243,164,181)",
              borderRadius: "50%",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "283px",
              left: "519px",
              width: 16,
              height: 16,
              backgroundColor: "rgb(243,164,181)",
              borderRadius: "50%",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "377px",
              left: "493px",
              width: 16,
              height: 16,
              backgroundColor: "rgb(243,164,181)",
              borderRadius: "50%",
              // border: "1px solid",
            },
          }, //3

          //right
          {
            boxMatchStyle: {
              position: "absolute",
              top: "146px",
              left: "693px",
              width: 16,
              height: 16,
              backgroundColor: "rgb(243,164,181)",
              borderRadius: "50%",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "271px",
              left: "693px",
              width: 16,
              height: 16,
              backgroundColor: "rgb(243,164,181)",
              borderRadius: "50%",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "333px",
              left: "693px",
              width: 16,
              height: 16,
              backgroundColor: "rgb(243,164,181)",
              borderRadius: "50%",
              // border: "1px solid",
            },
          }, //3
        ],
        answers: ["0-4", "1-3", "2-5"],
        initialValue: [],
      },
      Layout: `
	
			<div style='position: relative; width:100%; text-align:right; margin-bottom: 100px' >
				<img style='width:24cm; height: 10cm; margin-top: 50px' src="img/FriendsPlus/Page17/E2/1.jpg" />    
				
				<div style=" position: absolute; top: 169px; left: 511px;">
				<input id='0'/></div>
				<div style=" position: absolute; top: 263px; left: 470px;">
				<input  id='1'/></div>
	
				<div style=" position: absolute; top: 358px; left: 444px;">
				<input  id='2'/></div>
				</div>  
			  <input id='0' type= 'boxMatch' />
			  <input id='1' type= 'boxMatch' />
			  <input id='2' type= 'boxMatch' />
			  <input id='3' type= 'boxMatch' />
        <input id='4' type= 'boxMatch' />
			  <input id='5' type= 'boxMatch' />
		  `,
    },
  },
};

export default json;
