// import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
// import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  10: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P93-E10",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page93/Key/E10answerKey.png",
    inputSize: 250,
    maxLength: 20,
    checkUppercase: true,
    isHiddenCheck: true,
    textAlign: "center",
    stylesTextInput: {
      // background: 'none',
      fontSize: 23,
      height: 25,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 10</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='width: fit-content; display:flex; font-size: 22px; margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 0px 30px 0px 10px'>
              <div style='margin-right: 30px; border-right: solid 2px white; padding-right:10px'>
                He <b>likes </b>painting.<br>
                <b>Does </b>he <b>like </b>play<b>ing</b> chess?<br>
                They <b>like </b>read<b>ing</b> comics.<br>
                <b>Do </b>they <b>like </b>play<b>ing</b> chess?
              </div>  
              <div style=''>
                He <b>doesn’t like </b>play<b>ing</b> volleyball.<br>
                No, he <b>doesn’t</b>.<br>
                They <b>don’t like </b>fish<b>ing</b>.<br>
                <b>Yes,</b> they do.<br>
                We <b>like </b>fishing, <b>but </b>we <b>don’t like </b>reading comics.
              </div> 
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.(✗ = not like, ✓ = like)</b></span>
              <div style='margin-right:30px; line-height:50px'>
                <div><b>1. (✗, ✓)</b> They <u style='color: gray'>don’t like </u>flying kites, but they # playing outside.</div>
                <div><b>2. (✓)</b> # playing basketball? Yes, she does.</div>
                <div><b>3. (✓, ✗)</b> We # playing badminton, but we # playing chess.</div>
                <div><b>4. (✗)</b> He # reading comics.</div>
              </div>

      `,
        answer: [`like`, `Does she like`, `like`, `don't like`, `doesn't like`],
      },
    ],
  },
  11: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P93-E11",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page93/Key/E11answerKey.png",
    inputSize: 380,
    maxLength: 40,
    checkUppercase: true,
    isHiddenCheck: true,
    textAlign: "left",
    stylesTextInput: {
      // background: 'none',
      fontSize: 23,
      height: 29,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 11</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='width: fit-content; display:flex; font-size: 22px; margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 0px 30px 0px 10px'>
              <div style='width: fit-content; margin-right: 30px; border-right: solid 2px white; padding-right:10px'>
                <div style='width:80%'><b>Turn </b>left. <b>Go </b> straight <b>at </b>the traffic lights.</div>
              </div>  
              <div style=''>
              <div style='width:80%'>The park is <b>opposite</b> the train station.</div>
              </div> 
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b>Order the words.</b></span>
              <div style='margin-right:30px; line-height:50px'>
                <b>1. </b>bus&ensp; The&ensp; station&ensp; near&ensp; here.&ensp; is &ensp;  <u style='color: gray'>The bus station is near here.</u><br>
                <b>2. </b>Turn&ensp; library.&ensp; the&ensp; right&ensp; at<br>   <input id='0' width='750px'> <br>
                <b>3. </b>train&ensp; next&ensp; the&ensp; station.&ensp; to&ensp; library&ensp; is&ensp; the</u><br>
                <input id='1' width='750px'> <br>
                <b>4. </b> left&ensp; at&ensp; straight&ensp; Go&ensp; the&ensp; lights.&ensp; traffic&ensp; and&ensp; turn&ensp;</u><br>
                <input id='2' width='750px'> 
              </div>

      `,
        answer: [
          `Turn right at the library.`,
          `The library is next to the train station.`,
          `Go straight and turn left at the traffic lights.`,
        ],
      },
    ],
  },
  12: {
    // Exercise num
    unit: "Unit Grammar Time",
    id: "4WB- Grammar Time-P93-E12",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page93/Key/E12answerKey.png",
    inputSize: 250,
    maxLength: 10,
    checkUppercase: true,
    isHiddenCheck: true,
    textAlign: "center",
    stylesTextInput: {
      // background: 'none',
      fontSize: 23,
      height: 25,
    },
    // titleImage: "img/FriendsPlus/Page88/E1/1.jpg",
    titleQuestion: [
      {
        num: "",
        title:
          "<span style='; font-size: 30px;background: rgb(1 174 240);color: white; padding: 5px 10px; border-radius: 10px'>Unit 12</span>",
        color: "",
      },
    ],
    questionImage: [],
    questions: [
      {
        title: `
            <div style='width: fit-content; display:flex; font-size: 22px; margin: -52px 0px 40px 120px; border-radius: 5px;line-height:40px;background:rgb(199 234 254); padding: 0px 30px 0px 10px'>
              <div style='margin-right: 30px; border-right: solid 2px white; padding-right:10px'>
                I <b>was</b> sad.<br>
                He <b>wasn’t</b> sad.
              </div>  
              <div style=''>
              You <b>were</b> happy.<br>
              They <b>weren’t</b> cold.
              </div> 
            </div>
          <span style='color: black; font-size: 30px; margin-top:20px'><b> Write.</b></span>
              <div style='margin-right:30px; line-height:50px; font-size: 23px'>
                <div><b>1. (✗)</b> I  <u style='color: gray'>wasn’t </u>happy.</div>
                <div><b>2. (✓)</b> You # good.</div>
                <div><b>3. (✓)</b> He # hot.</div>
                <div><b>4. (✗)</b> They # hungry.</div>
              </div>

      `,
        answer: [`were`, `was`, `weren't`],
      },
    ],
  },
};

export default json;
