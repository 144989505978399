import Circle_Write from '../../components/ExcerciseTypes/Circle_Write'
import D1 from '../../components/ExcerciseTypes/Design/TypeIn'
import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 6',
		id: '4WB-U6-P46-E1',
		audio: '',
		video: '',
		exerciseKey: 'img/FriendsPlus/Page46/E1/Key/E1answerKey.png',

		component: DesignUnderLine,
		totalInput: 3,
		titleQuestion: [
			{
				num: '1',
				title: 'Circle the correct words.',
				color: '#234090',
			},
		],
isHiddenCheck:true,
		questionImage: [
			// Q1
			[
				{ url: 'img/FriendsPlus/Page46/E1/1.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/2.jpg'  },
				{ url: 'img/FriendsPlus/Page46/E1/3.jpg',input: 1 },
				{ url: 'img/FriendsPlus/Page46/E1/4.jpg' },

			],
			[
				{ url: 'img/FriendsPlus/Page46/E1/5.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/6.jpg', input: 2 },
				{ url: 'img/FriendsPlus/Page46/E1/7.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/8.jpg' , input: 3, isCorrect:true, },

			],
			[{ url: 'img/FriendsPlus/Page46/E1/9.jpg' }],
			[
				{ url: 'img/FriendsPlus/Page46/E1/10.jpg', input: 4,isCorrect:true, },
				{ url: 'img/FriendsPlus/Page46/E1/11.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/12.jpg', input: 5 },
				{ url: 'img/FriendsPlus/Page46/E1/13.jpg' },
			],
			[
				{ url: 'img/FriendsPlus/Page46/E1/14.jpg' },
				{ url: 'img/FriendsPlus/Page46/E1/15.jpg', input: 6,},
				{ url: 'img/FriendsPlus/Page46/E1/16.jpg', },
				{ url: 'img/FriendsPlus/Page46/E1/17.jpg' ,input: 7,isCorrect:true, },
			],
			
		],
	},
	2: {
		unit: 'Unit 6',
		id: '4WB-U6-P46-E2',
		audio: '',
		video: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page46/E2/Key/E2answerKey.png',
		titleQuestion: [
			{
				num: '2',
				title: `
				Read the poster in the Student Book. Write <i>T</i> (true) or <i>F</i> (false).
			  `,
				color: '#234090',
			},
		],
		titleImage: '',
		questionImage: [],
		inputSize: 50,
		maxLength: 1,
		stylesTextInput: {
			background: 'none',
			// borderBottom: "none",
			fontSize: 23,
			height: 29,
			textTransform: 'uppercase',
			width: 130,
		},
		textAlign: 'center',
		isHiddenCheck: true,
		questions: [
			{
				title: `
					<div style=" position: relative; display: flex; ">
             <div style=" width: 350px>
               <div style=" position: absolute;"><b>1. </b> There is a school trip to the farm.</div>
               <div style=" position: absolute; margin-top: 50px "><b>2. </b> There are lizards and crocodiles.</div>
               <div style=" position: absolute; margin-top: 100px "><b>3. </b> Bring your school bag.</div>
               <div style=" position: absolute; margin-top: 150px "><b>4. </b> Don't touch the water.</div>
               <div style=" position: absolute; margin-top: 200px "><b>5. </b> Listen to the pilot.</div>
             </div>
             <div style= " margin-left: 400px; margin-top: -40px; margin-bottom: 300px">
               <div style=" position: absolute; border-bottom: 2px solid black; width: 130px; text-align: center ">F</div>
               <div style=" position: absolute; margin-top: 50px "><input id='0'></input></div>
               <div style=" position: absolute; margin-top: 100px "><input id='1'></input></div>
               <div style=" position: absolute; margin-top: 150px "><input id='2'></input></div>
               <div style=" position: absolute; margin-top: 200px "><input id='3'></input></div>
             </div>
					</div>
				
			  
			  `,
				answer: ['T', 'F', 'T', 'F'],
			},
		],
	},
}

export default json
