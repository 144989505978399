import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P9-E1",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page9/E1/Key/E1answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    component: T6,
    inputSize: 270,
    isHiddenCheck: true,
    textAlign: "center",
    maxLength: 18,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 28,
    },
    titleQuestion: [
      {
        num: "1",
        title: "Write.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <div style=' position: relative; '>
            
						<div> <img style='position:relative; width:20cm' src='img/FriendsPlus/Page9/E1/1.jpg' /> </div>
            <div style=" position: absolute; top: 298px; left: 221px; height: 20px"><input id='0' width='200px'></input></div>            
            <div style=" position: absolute; top: 335px; left: 250px; height: 20px "><input id='1' width='300px'></input></div>
            <div style=" position: absolute; top: 372px; left: 243px; height: 20px"><input id='2' width='300px'></input></div>
            <div style=" position: absolute; top: 408px; left: 250px; height: 20px"><input id='3' width='300px'></input></div>
           

				</div>

          `,
        answer: ["teddy bear", "lions", "green", "chicken rice"],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Starter",
    id: "4WB-S-P9-E2",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page9/E2/Key/E2answerKey.png",

    component: T6,
    // totalInput: 2,
    titleQuestion: [
      {
        num: "2",
        title: " Read. Put a check (✓) or a cross (✗). ",
        color: "#234090",
      },
    ],
    selectStyle: {
      height: 35,
      width: 35,
      textAlign: "center",
    },
    selectOptionRandom: true,
    selectOptionValues: ["✓", "X"],
    questionImage: [],
    questions: [
      {
        title: `
        <img style='position:relative; width:19cm' src='img/FriendsPlus/Page9/E2/1.png' />
        <img  style='padding-top:30px' src='img/FriendsPlus/Page9/E2/1.jpg' />
        <div style='position: relative;'>
          <div style=" position: absolute; top: -116px; left: 351px; "><select id=0></select></div>
          <div style=" position: absolute; top: -44px; left: 351px; "><select id=1></select></div>
          <div style=" position: absolute; top: -161px; left: 925px; "><select id=2></select></div>
          <div style=" position: absolute; top: -89px; left: 925px; "><select id=3></select></div>
          
         
        </div> 
        `,
        answer: ["X", "✓", "✓", "X"],
      },
    ],
  },

  3: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P9-E3",
    audio: "",
    hideBtnFooter: true,
    exerciseKey: "img/FriendsPlus/Page9/Key/E3answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    component: T6,
    inputSize: 270,
    textAlign: "left",
    maxLength: 50,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 20,
      height: 22,
    },
    titleQuestion: [
      {
        num: "3",
        title: "Write and draw. Talk about your friend.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `


        <div style=" position: relative; padding-top:20px; background-color:none;  ">
						<div> <img style='width:22cm' src='img/FriendsPlus/Page9/E3/1.jpg' /> </div>

            <div style=" position: absolute; top: 50px; left: 120px; height: 20px">#</div>            
            <div style=" position: absolute; top: 85px; left: 120px; height: 20px">#</div>            
            
            <div style=" position: absolute; top: 120px; left: 243px; height: 20px">#</div>
            <div style=" position: absolute; top: 157px; left: 108px; height: 20px">#</div>

            <div style=" position: absolute; top: 187px; left: 136px; height: 20px">#</div>            
            <div style=" position: absolute; top: 218px; left: 178px; height: 20px">#</div>            

            <div style=" position: absolute; top: 255px; left: 220px; height: 20px">#</div>
						
            <div style="position: absolute; top: 290px; left: 207px; height: 20px">#</div>
            <div style=" position: absolute; top: 322px; left: 207px; height: 20px">#</div>
					</div>
        
          
          `,
        answer: ["", "", "", "", "", "", "", "", ""],
      },
    ],
  },
};

export default json;
