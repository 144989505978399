import T6 from '../../components/ExcerciseTypes/TypeIn/T6'
import MatchDots from '../../components/ExcerciseTypes/LineTo/MatchDots'

const json = {
	1: {
		// Exercise num
		unit: 'Unit 11',
		id: '4WB-U11-P76-E1',
		audio: '',
		component: T6,
		exerciseKey: 'img/FriendsPlus/Page76/E1/Key/E1answerKey.png',
		inputSize: 100,
		textAlign: 'center',
		maxLength: 20,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '1',
				title: 'Read, look, and write.',
				color: '#234090',
			},
		],

		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 22,
			height: 30,
			width: 200,
		},
		questions: [
			{
				title: `
       <div style='position:relative'>
       <img style='width:20cm' src='img/FriendsPlus/Page76/E1/1.jpg' />

        <div style=" position: absolute; top: 93px; left: 194px; ">#</div>
        <div style=" position: absolute; top: 130px; left: 220px; ">#</div>
        <div style=" position: absolute; top: 167px; left: 179px; ">#</div>

        <div style=" position: absolute; top: 210px; left: 229px; ">#</div>
        <div style=" position: absolute; top: 210px; left: 495px; ">#</div>
        <div style=" position: absolute; top: 327px; left: 128px; ">#</div>
     
      </div>
            
          `,
				answer: [
					'straight',
					'next to',
					'right',
					'bookstore',
					'between',
					'left',
				],
			},
		],
	},
	2: {
		// Exercise num
		unit: 'Unit 11',
		id: '4WB-U11-P76-E2',
		audio: '',
		component: T6,
		hideBtnFooter: true,
		// exerciseKey: 'img/FriendsPlus/Page76/E2/Key/E1answerKey.png',
		inputSize: 100,
		textAlign: 'center',
		maxLength: 30,
		isHiddenCheck: true,
		titleQuestion: [
			{
				num: '2',
				title: 'Read again. Draw the route on the map.',
				color: '#234090',
			},
		],

		stylesTextInput: {
			background: 'none',
			borderBottom: 'none',
			fontSize: 22,
			height: 30,
			width: 200,
		},
		questions: [
			{
				title: `
       <div style='max-width:800px'>
			 <img style='width:18cm' src='img/FriendsPlus/Page76/E2/2.jpg' />
       <img style='width:21cm' src='img/FriendsPlus/Page76/E2/1.jpg' />
     
      </div>
            
          `,
				answer: [],
			},
		],
	},
}

export default json
