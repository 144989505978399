import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P62-E1",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page62/E1/Key/E1answerKey.png",
    inputSize: 100,
    textAlign: "center",
    maxLength: 16,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "1",
        title: "Write.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "go home",
          "<s>get up</s>",
          "go to bed",
          "go to school",
          "have breakfast",
          "have dinner",
        ],
        width: 600,
        borderColor: "rgb(244,147,149)",
      },
    ],
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 22,
      height: 30,
      width: 200,
    },
    questions: [
      {
        title: `
        <div style='margin-left:100px'><hintBox id='0'></hintBox></div>
       <div style='position:relative'>
       <img style='width:20cm' src='img/FriendsPlus/Page62/E1/1.jpg' />

        <div style=" position: absolute; top: 160px; left: 265px; ">#</div>
        <div style=" position: absolute; top: 160px; left: 527px; ">#</div>

        <div style=" position: absolute; top: 371px; left: 8px; ">#</div>
        <div style=" position: absolute; top: 371px; left: 265px; ">#</div>
        <div style=" position: absolute; top: 371px; left: 527px; ">#</div>
     
      </div>
            
          `,
        answer: [
          "have breakfast",
          "go to school",
          "go home",
          "have dinner",
          "go to bed",
        ],
      },
    ],
  },
  2: {
    // Exercise num
    unit: "Unit 9",
    id: "4WB-U9-P62-E2",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page62/E2/Key/E2answerKey.png",
    // inputSize: 250,
    textAlign: "left",
    // maxLength: 40,
    isHiddenCheck: true,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "2",
        title: " Order the words.",
        color: "#234090",
      },
    ],
    stylesTextInput: {
      background: "none",
      // borderBottom: "none",
      fontSize: 22,
      height: 30,
      width: 400,
    },
    questions: [
      {
        title: `
       <div style='position:relative'>
       <img style='width:22cm' src='img/FriendsPlus/Page62/E2/1.jpg' />

        <div style=" position: absolute; top: 105px; left: 544px">#</input></div>
        <div style=" position: absolute; top: 159px; left: 544px">#</input></div>
        <div style=" position: absolute; top: 212px; left: 544px">#</input></div>
        <div style=" position: absolute; top: 267px; left: 544px">#</input></div>
        <div style=" position: absolute; top: 320px; left: 544px">#</input></div>
     
      </div>
            
          `,
        answer: [
          `He goes home at five o'clock.`,
          `I get up at six o'clock.`,
          `We have dinner at seven o'clock.`,
          "He has breakfast at six thirty.",
          "She goes to bed at nine fifteen.",
        ],
      },
    ],
  },
};

export default json;
