import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    // Exercise num
    unit: "Starter",
    id: "4WB-S-P7-E1",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page7/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: "Match.",
        color: "#2E479B",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: true,
        boxMatch: [
          //above
          {
            boxMatchStyle: {
              position: "absolute",
              top: "45px",
              left: "126px",
              width: 110,
              height: 45,
              // backgroundColor: "red",
              // border: "1px solid",
            },
          }, //0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "45px",
              left: "362px",
              width: 110,
              height: 45,
              // backgroundColor: "blue",
              // border: "1px solid",
            },
          }, //1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "45px",
              left: "580px",
              width: 110,
              height: 45,
              // backgroundColor: "yellow",
              // border: "1px solid",
            },
          }, //2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "45px",
              left: "818px",
              width: 130,
              height: 45,
              // backgroundColor: "green",
              // border: "1px solid",
            },
          }, //3
          //below
          {
            boxMatchStyle: {
              position: "absolute",
              top: "257px",
              left: "132px",
              width: 110,
              height: 45,
              // backgroundColor: "red",
              // border: "1px solid",
            },
          }, //4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "257px",
              left: "367px",
              width: 110,
              height: 45,
              // backgroundColor: "blue",
              // border: "1px solid",
            },
          }, //5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "257px",
              left: "603px",
              width: 110,
              height: 45,
              // backgroundColor: "green",
              // border: "1px solid",
            },
          }, //6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "257px",
              left: "822px",
              width: 110,
              height: 45,
              // backgroundColor: "black",
              // border: "1px solid",
            },
          }, //7
          //mid
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "77px",
              width: 119,
              height: 63,
              // backgroundColor: "gray",
              // border: "1px solid",
            },
          }, //8
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "346px",
              width: 145,
              height: 63,
              // backgroundColor: "yellow",
              // border: "1px solid",
            },
          }, //9
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "603px",
              width: 119,
              height: 63,
              // backgroundColor: "black",
              // border: "1px solid",
            },
          }, //10
          {
            boxMatchStyle: {
              position: "absolute",
              top: "150px",
              left: "819px",
              width: 119,
              height: 63,
              // backgroundColor: "blue",
              // border: "1px solid",
            },
          }, //11
        ],
        answers: ["3-8", "5-8", "7-9", "10-6", "10-2", "1-11", "11-4"],
        initialValue: [],
      },
      Layout: `
      
      <img style='padding-top:30px; width:25cm; margin-bottom:80px' src="img/FriendsPlus/Page7/E1/1.jpg" />
          <input id='0' type= 'boxMatch' />
          <input id='1' type= 'boxMatch' />
          <input id='2' type= 'boxMatch' />
          <input id='3' type= 'boxMatch' />
          <input id='4' type= 'boxMatch' />
          <input id='5' type= 'boxMatch' />
          <input id='6' type= 'boxMatch' />
          <input id='7' type= 'boxMatch' />
          <input id='8' type= 'boxMatch' />
          <input id='9' type= 'boxMatch' />
          <input id='10' type= 'boxMatch' />
          <input id='11' type= 'boxMatch' />
          
      `,
    },
  },
  2: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P7-E2",
    audio: "",
    component: T6,
    hideBtnFooter: true,
    // exerciseKey: "img/FriendsPlus/Page7/E3/Key/E3answerKey.png",
    inputSize: 130,
    textAlign: "center",
    maxLength: 20,

    titleQuestion: [
      {
        num: "2",
        title: "Read and draw.",
        color: "#234090",
      },
    ],

    questions: [
      {
        title: `
        <img style='width:20cm' src="img/FriendsPlus/Page7/E2/1.jpg" />
          
          `,
        answer: [""],
      },
    ],
  },
  3: {
    // Exercise num
    unit: "Stater",
    id: "4WB-S-P7-E3",
    audio: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page7/E3/Key/E3answerKey.png",
    // exerciseKey: 'img/FriendsPlus/Page39/Key/E2answerKey.png',
    inputSize: 130,
    textAlign: "center",
    maxLength: 20,
    isHiddenCheck: true,
    titleQuestion: [
      {
        num: "3",
        title: "Look and write.",
        color: "#234090",
      },
    ],
    hintBox: [
      {
        src: [
          "Tim",
          "brown",
          "straight",
          "short",
          "<s>Roxy</s>",
          "green",
          "curly",
          "Billy",
        ],
        width: 700,
        borderColor: "rgb(8 177 239)",
      },
    ],
    questions: [
      {
        title: `
        <img src="img/FriendsPlus/Page7/E3/1.jpg" />
        <div style='line-height:50px; padding-top:20px'>
          <div style='display:flex'><b>1. </b><div style='width:60%'>What color is number <u style='color:gray; font-style:italic'>&ensp;thirteen.&ensp;</u> ?</div> &ensp; It’s black.  </div>
          <div style='display:flex'><b>2. </b><div style='width:60%'>What color is number fifteen?</div> &ensp; It’s  # . </div>
          <div style='display:flex'><b>3. </b><div style='width:60%'>What color is number # ?</div> &ensp; It’s yellow. </div>
          <div style='display:flex'><b>4. </b><div style='width:60%'>What color is number eight?</div> &ensp; It’s  # . </div>
          <div style='display:flex'><b>5. </b><div style='width:60%'>What color is number # ?</div> &ensp; It’s green. </div>
        </div>
          
          `,
        answer: ["pink", "12", "orange", "20"],
      },
    ],
  },
};

export default json;
