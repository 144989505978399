import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import { CgEnter } from "react-icons/cg";
const json = {
  1: {
    unit: "Unit 6",
    id: "4WB-U6-P42-E1",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page42/E1/Key/E1answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title: `
				Look at the picture. Look at the letters. Write the word. 
			  `,
        color: "#234090",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 50,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 24,
      height: 29,
      // textTransform: 'uppercase',
      width: 267,
    },
    maxLength: 9,
    textAlign: "center",

    isHiddenCheck: true,
    selectStyle: {
      color: "#00ADFE",
      width: 60,
      textAlign: "center",
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    // selectOptionValues: ['1', '2', '3', '4', '5'],
    questions: [
      {
        title: `
					<div style=" position: relative ">
            <img src='img/FriendsPlus/Page42/E1/1.jpg' style='width: 19cm'>
            
            <div style=" position: absolute; top: 128px; left: 422px; "><input id='0'></input></div>
            <div style=" position: absolute; top: 296px; left: 25px; "><input id='1'></input></div>
            <div style=" position: absolute; top: 296px; left: 422px; "><input id='2'></input></div>
            <div style=" position: absolute; top: 466px; left: 25px; "><input id='3'></input></div>
            <div style=" position: absolute; top: 466px; left: 422px; "><input id='3'></input></div>
					</div>
				
			  
			  `,
        answer: ["kangaroo", "lizard", "penguin", "zebra", "crocodile"],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "4WB-U6-P42-E2",
    audio: "",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page42/E2/Key/E2answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title: `
				Write.
			  `,
        color: "#234090",
      },
    ],
    titleImage: "",
    questionImage: [],
    inputSize: 50,
    maxLength: 40,
    checkUppercase: true,
    stylesTextInput: {
      background: "none",
      borderBottom: "none",
      fontSize: 23,
      height: 29,
      // textTransform: 'uppercase',
      width: 288,
    },
    maxLength: 25,
    textAlign: "center",

    isHiddenCheck: true,
    selectStyle: {
      color: "#00ADFE",
      width: 60,
      textAlign: "center",
      // fontSize: 17,
    },
    // selectOptionRandom: true,
    // selectOptionValues: ['1', '2', '3', '4', '5'],
    questions: [
      {
        title: `
					<div style=" position: relative ">
            <img src='img/FriendsPlus/Page42/E2/1.jpg' style='width:21cm'>
            
            <div style=" position: absolute; top: 225px; left: 107px; "><input id='0'></input></div>
            <div style=" position: absolute; top: 367px; left: 107px; "><input id='1'></input></div>
            <div style=" position: absolute; top: 81px; left: 512px; "><input id='2'></input></div>
            <div style=" position: absolute; top: 225px; left: 512px; "><input id='3'></input></div>
            <div style=" position: absolute; top: 367px; left: 512px; "><input id='3'></input></div>
					</div>
			  `,
        answer: [
          `It's a camel.`,
          `It's a kangaroo.`,
          `It's a penguin.`,
          `It's a crocodile.`,
          `It's a lizard.`,
        ],
      },
    ],
  },
};

export default json;
